import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MasipService } from '../../services/masip.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  loginForm!: FormGroup;
  showingPassword: boolean = false;
  errorMessage: string = '';

  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService, private masipService: MasipService, private route: ActivatedRoute) { }

  ngOnInit() {
    localStorage.removeItem('accessAsUser');
    this.authService.getUserMe().subscribe(me => {
      if (me) {
        this.redirect(me);
      }
    })
    this.route.queryParams.subscribe(params => {
      if (params['user'] && params['password']) {
        this.login(params['user'], params['password'], true);
      }
    });
    this.loginForm = this.formBuilder.group({
      user: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
    // this.loginForm = this.formBuilder.group({
    //   user: ['B98459324', [Validators.required]],
    //   password: ['6487e36015be56c4bd8016d3498169aa', [Validators.required]]
    // });
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.doLogin();
    }

  }

  doLogin() {
    this.errorMessage = '';
    this.login(this.loginForm.get('user')?.value, this.loginForm.get('password')?.value, false);
  }

  login(user: string, password: string, redirect: boolean) {
    this.authService.login(user, password, redirect).subscribe(resp => {
      this.authService.getUserMe().subscribe(me => {
        this.redirect(me);
      })

    },
      error => {
        this.errorMessage = 'Usuario o contraseña incorrectos';
      })
  }

  redirect(me: any) {
    if (me.data.admin) {
      this.masipService.setAdmin(true);
      this.router.navigateByUrl('/clientes');
    }
    if (me.data.user) {
      this.masipService.setUser(true);
      localStorage.setItem('accessAsUser', me.data.fiscal_id)
      this.router.navigateByUrl('/mis-lineas');
    }
  }
}
