import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {


  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private authService: AuthService
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const jwt_token = localStorage.getItem('jwt_token');
    if (jwt_token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${jwt_token}`, //Authorization
          Accept: 'application/json'
        }
      });
    }

    return next.handle(request).pipe(catchError(
      (err: any) => {
        console.log(err);

        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.handle401Error(request, next);
          }
        }
        return throwError(() => new Error(JSON.stringify(err)));
      }
    ));

  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (this.refreshTokenInProgress == false) {

      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);

      this.authService.refreshToken().subscribe(resp => {
        this.refreshTokenInProgress = false;
        this.refreshTokenSubject.next(resp);
        window.location.reload();
      }, error => {
        this.refreshTokenInProgress = false;

        this.router.navigate(['/']);
      });

    }
  }

  // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  //   this.authService.refreshToken().subscribe(resp => {
  //     return next.handle(request);
  //   }, error => {
  //     this.router.navigate(['/']);
  //   });
  // }
}
