import { Injectable } from '@angular/core';
import { AppSettings } from '../app-settings';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasipService {

  private adminSelect = new BehaviorSubject<boolean>(false);
  public adminStorage = this.adminSelect.asObservable();

  private userSelect = new BehaviorSubject<boolean>(false);
  public userStorage = this.userSelect.asObservable();

  private apiLoadingSelect = new BehaviorSubject<boolean>(false);
  public apiLoadingStorage = this.apiLoadingSelect.asObservable();

  private apiLoadingGlobalSelect = new BehaviorSubject<boolean>(false);
  public apiLoadingGlobalStorage = this.apiLoadingGlobalSelect.asObservable();

  constructor(private http: HttpClient) { }


  getAdmin() {
    return this.adminSelect.getValue();
  }

  setAdmin(admin: any) {
    this.adminSelect.next(admin);
  }

  getUser() {
    return this.userSelect.getValue();
  }

  setUser(user: any) {
    this.userSelect.next(user);
  }

  getApiLoading() {
    return this.apiLoadingSelect.getValue();
  }

  setApiLoading(loading: any) {
    this.apiLoadingSelect.next(loading);
  }

  getApiLoadingGlobal() {
    return this.apiLoadingGlobalSelect.getValue();
  }

  setApiLoadingGlobal(loading: any) {
    this.apiLoadingGlobalSelect.next(loading);
  }

  getLinesByUser(perPage: number = 9, page: number = 1, fiscal_id: string, ip: number, search?: string) {
    let url = AppSettings.API_URI + '/lines?fiscal_id=' + fiscal_id + '&page=' + page + '&num=' + perPage + '&ip=' + ip;
    if (search) {
      url = url + '&search=' + search;
    }
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  getLineServices(phone: string) {
    let url = AppSettings.API_URI + '/lines/services/' + phone;
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  getBlacklist(phone: string) {
    let url = AppSettings.API_URI + '/blacklist/' + phone;
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  getWhitelist(phone: string) {
    let url = AppSettings.API_URI + '/whitelist/' + phone;
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }


  getFiberByUser(perPage: number = 9, page: number = 1, fiscal_id: string, search?: string) {
    let url = AppSettings.API_URI + '/getUserFiberLines?fiscal_id=' + fiscal_id + '&page=' + page + '&num=' + perPage;
    if (search) {
      url = url + '&search=' + search;
    }
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  getClients(perPage: number = 9, page: number = 1, search?: string) {
    let url = AppSettings.API_URI + '/users?page=' + page + '&num=' + perPage;
    if (search) {
      url = url + '&search=' + search;
    }
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  getRules(ip: string, mode: string) {
    let url = AppSettings.API_URI + '/getUserRules?address=' + ip;
    if (mode == 'fiber') {
      url = url + '%2F32';
    }
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  getQueue(fiscal_id: string, page: number, initialDate: string, endDate: string, search: string, type: string) {
    let url = AppSettings.API_URI + '/getQueue?per_pages=9' + '&page=' + page;
    if (fiscal_id !== '') {
      url = url + '&fiscal_id=' + fiscal_id;
    }
    if (initialDate !== '') {
      url = url + '&initialDate=' + initialDate;
    }
    if (endDate !== '') {
      url = url + '&endDate=' + endDate;
    }
    if (search !== '') {
      url = url + '&search=' + search;
    }
    if (type !== '') {
      url = url + '&type=' + type;
    }
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  getConfigAdmin() {
    let url = AppSettings.API_URI + '/getConfig';
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  postConfigAdmin(payload: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/updateConfig`;
    return this.http.post<any>(url, payload, formHeaders).pipe(
      map(data => {
        return data;
      }), catchError(err => {
        return of(false);
      })
    );
  }

  postWhitelistQueue(payload: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/AddWhitelistToQueue`;
    return this.http.post<any>(url, payload, formHeaders).pipe(
      map(data => {
        return data;
      }), catchError(err => {
        return of(false);
      })
    );
  }

  postBlacklistQueue(payload: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/AddBlacklistToQueue`;
    return this.http.post<any>(url, payload, formHeaders).pipe(
      map(data => {
        return data;
      }), catchError(err => {
        return of(false);
      })
    );
  }

  postConfigLineQueue(payload: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/AddConfigLineToQueue`;
    return this.http.post<any>(url, payload, formHeaders).pipe(
      map(data => {
        return data;
      }), catchError(err => {
        return of(false);
      })
    );
  }

  postRulesQueue(payload: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/AddmodifyTimeRangesToQueue`;
    return this.http.post<any>(url, payload, formHeaders).pipe(
      map(data => {
        return data;
      }), catchError(err => {
        return of(false);
      })
    );
  }

  getPendingInQueue(id: string) {
    let url = '';
    if (id.includes('.')) {
      url = AppSettings.API_URI + '/pendingQueue?address=' + id;
    } else {
      url = AppSettings.API_URI + '/pendingQueue?phone_line=' + id;

    }
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  executeFromQueue(id: string) {
    let url = AppSettings.API_URI + '/executeQueue/' + id;
    return this.http.put<any>(url, {}).pipe(
      map(data => {
        return data;
      })
    );
  }

  cancelFromQueue(id: string) {
    let url = AppSettings.API_URI + '/cancelQueue/' + id;
    return this.http.put<any>(url, {}).pipe(
      map(data => {
        return data;
      })
    );
  }

  getQueueById(id: string) {
    let url = AppSettings.API_URI + '/getQueuebyId/' + id;
    return this.http.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }
}
