<div class="clients-wrapper">
  <div class="d-flex justify-content-between align-items-center w-100">
    <div style="max-width: 620px; width: 620px;">
      <app-searchbar [ngClass]="loadingApi==true ? 'disabled' : ''"
        placeholder="Buscar cliente por nombre, número de teléfono o DNI/NIF"
        (emitSearch)="searchData($event)"></app-searchbar>
    </div>
    <app-welcome-user></app-welcome-user>
  </div>
  <div class="content-wrapper">
    <div class="buttons-bar d-flex justify-content-between align-items-center w-100">
      <div class="d-flex align-items-center" style="gap: 8px;">
        <p class="title">Clientes:</p>
      </div>
      <div class="arrows-wrapper d-flex align-items-center">
        <i [ngClass]="loadingApi==true || actualPage==1 ? 'disabled' : 'enabled'" class="far fa-chevron-left"
          (click)="changePage(actualPage-1)"></i>
        <i [ngClass]="loadingApi==true || actualPage==totalPages ? 'disabled' : 'enabled'" class="far fa-chevron-right"
          (click)="changePage(actualPage+1)"></i>
      </div>
    </div>
    <div class="table-wrapper table-scroll">
      <table *ngIf="loading" mat-table class="data-table" style="margin-bottom: 0px;">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Nombre </th>
        </ng-container>

        <ng-container matColumnDef="dni">
          <th mat-header-cell *matHeaderCellDef> DNI/NIF </th>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> Teléfono de contacto </th>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Acciones </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div *ngIf="loading" class="loading-wrapper">
        <mat-spinner></mat-spinner>
        <p>Cargando...</p>
      </div>

      <table *ngIf="!loading" mat-table [dataSource]="data" class="data-table">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Nombre</th>
          <td mat-cell *matCellDef="let element" class="bold-cell">
            <p class="name-line">{{element.name.toUpperCase()}}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="dni">
          <th mat-header-cell *matHeaderCellDef> DNI/NIF </th>
          <td mat-cell *matCellDef="let element" class="bold-cell"> {{element.fiscalId}}</td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> Teléfono de contacto </th>
          <td mat-cell *matCellDef="let element" class="bold-cell">{{element.contactPhone}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Acciones </th>
          <td mat-cell *matCellDef="let element" class="regular-cell" (click)="enterAsUser(element)">
            <span>Entrar</span> <i class="fal fa-arrow-right-to-bracket" style="margin-left: 10px;"></i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div *ngIf="!loading" class="d-flex justify-content-center align-items-center w-100" style="margin-top: 37px;">
      <app-paginator style="margin-top: var(--space-blocks);" class="paddings-bottom" [totalPages]="totalPages"
        [actualPage]="actualPage" (newPageEvent)="changePage($event)"></app-paginator>
    </div>

  </div>
</div>