import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { debounceTime, fromEvent } from 'rxjs';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrl: './searchbar.component.scss'
})
export class SearchbarComponent {

  @Input() placeholder: string = 'Buscar';
  @Output() emitSearch = new EventEmitter<number>();
  @ViewChild('searchbar') searchbar!: ElementRef;

  ngAfterViewInit() {
    fromEvent(this.searchbar.nativeElement, 'input').pipe(
      debounceTime(1500)
    ).subscribe(() => {
      this.search();
    });
  }

  search() {
    if (this.searchbar.nativeElement.value === '' || this.searchbar.nativeElement.value.trim().length !== 0) {
      this.emitSearch.emit(this.searchbar.nativeElement.value);
    }
  }
}
