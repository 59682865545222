<mat-drawer-container class="drawer-container" autosize>
  <mat-drawer #drawer [mode]="'over'" class="sidenav" mode="side">
    <div class="sidemenu-wrapper">
      <div class="sidemenu-header">
        <img src="../../../assets/images/logo.png" alt="">
        <i class="fal fa-times" (click)="drawer.close()"></i>
      </div>
      <div class="user-welcome">
        <i class="fal fa-circle-user"></i>
        <p>Hola, Antonio Medina Ruiz</p>
      </div>
      <div style="flex: 1 1 auto;">

        <app-sidebar></app-sidebar>
      </div>
    </div>
  </mat-drawer>

  <div class="panel-router-wrapper d-flex justify-content-between w-100">
    <div class="mobile-header">
      <img src="../../../assets/images/logo.png" alt="">
      <div class="menu-icon" (click)="drawer.toggle()">
        <i class="fal fa-bars-staggered"></i>
      </div>
    </div>
    <app-sidebar class="sidebar-desktop"></app-sidebar>
    <div class="content-wrapper" id="content-scroll">
      <router-outlet></router-outlet>
    </div>
  </div>

</mat-drawer-container>

<div *ngIf="loadingApi==true" class="loading-panel">
  <div class="loading-wrapper">
    <mat-spinner></mat-spinner>
    <p>Cargando, esto podría tardar unos segundos...</p>
  </div>
</div>