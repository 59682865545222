import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { MyLinesComponent } from './pages/my-lines/my-lines.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { AuthGuard } from './guards/auth.guard';
import { ClientsComponent } from './pages/clients/clients.component';
import { AdminGuard } from './guards/admin.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, title: 'Iniciar sesión' },
  { path: 'mis-lineas', component: MyLinesComponent, title: 'Mis líneas', canActivate: [AuthGuard] },
  { path: 'configuraciones', component: SettingsComponent, title: 'Configuraciones', canActivate: [AuthGuard] },
  { path: 'clientes', component: ClientsComponent, title: 'Clientes', canActivate: [AuthGuard, AdminGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
