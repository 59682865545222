import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MasipService } from '../../services/masip.service';
import { MatDialog } from '@angular/material/dialog';
import { AdminSettingsComponent } from '../../dialogs/admin-settings/admin-settings.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {

  admin: boolean = false;
  user: boolean = false;
  adminAsUser: any;

  loadingApi: boolean = false;

  constructor(protected router: Router, private authService: AuthService, private masipService: MasipService, private dialog: MatDialog) { }

  ngOnInit() {
    this.masipService.setApiLoadingGlobal(true);

    this.masipService.apiLoadingStorage.subscribe(resp => {
      this.loadingApi = resp;
    })
    if (this.router.url !== '/login') {
      this.authService.getUserMe().subscribe(resp => {
        if (resp.data.admin) {
          this.admin = true;
          if (localStorage.getItem('accessAsUser') !== null) {
            this.user = true;
            this.masipService.getClients(1, 1, String(localStorage.getItem('accessAsUser') || '')).subscribe(resp => {
              this.adminAsUser = resp.data.data[0];
            })
          }
        }
        if (resp.data.user) {
          this.user = true;
        }
      })

      this.masipService.adminStorage.subscribe(resp => {
        this.admin = resp;
      })
      this.masipService.userStorage.subscribe(resp => {
        this.user = resp;

        this.authService.getUserMe().subscribe(resp => {
          this.adminAsUser = null;
          if (resp.data.admin) {
            this.admin = true;
            if (localStorage.getItem('accessAsUser') !== null) {
              this.user = true;
              this.masipService.getClients(1, 1, String(localStorage.getItem('accessAsUser') || '')).subscribe(resp => {
                this.adminAsUser = resp.data.data[0];
              })
            }
          }
          if (resp.data.user) {
            this.user = true;
          }
        })
      })
    }
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  logout() {
    this.authService.logout().subscribe(resp => {
      localStorage.removeItem('accessAsUser');
      this.router.navigateByUrl('/login');
    });
  }

  logoutAsUser() {
    localStorage.removeItem('accessAsUser');
    this.router.navigateByUrl('/clientes');
    this.masipService.setUser(false);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);
  }

  openAdminConfig() {
    const dialogRef = this.dialog.open(AdminSettingsComponent, {
      panelClass: 'admin-config-dialog',
    });
    return dialogRef.afterClosed();
  }
}
