import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accent-button',
  templateUrl: './accent-button.component.html',
  styleUrl: './accent-button.component.scss'
})
export class AccentButtonComponent {
  @Input() text = '';
  @Input() selected = false;

}
