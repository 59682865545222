import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-welcome-user',
  templateUrl: './welcome-user.component.html',
  styleUrl: './welcome-user.component.scss'
})
export class WelcomeUserComponent {

  username: string = '';

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.authService.getUserMe().subscribe(resp => {
      this.username = resp.data.email;
    })
  }
}
