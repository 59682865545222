<div class="config-ip-wrapper">
  <div *ngIf="loading" class="loading-wrapper" style="flex: 1 1 auto;">
    <mat-spinner></mat-spinner>
    <p>Cargando...</p>
  </div>
  <div *ngIf="!loading && rules.length==0" class="subheader d-flex justify-content-start align-items-center">
    <p>Actualmente no hay limitaciones para ninguna APP</p>
  </div>
  <div *ngIf="!loading && editing==false" class="add-limit d-flex justify-content-start align-items-center"
    style="padding: 18px 37px 18px 37px;" (click)="editing=true" [class]="deletingRules.length>0? 'disabled' : ''">
    <i class="fal fa-plus"></i>
    <p>Crear limitación de acceso a una o varias APPS</p>
  </div>
  <div *ngIf="!loading && rules.length>0 && editing==false"
    class="add-limit d-flex justify-content-start align-items-start flex-column">
    <div *ngFor="let rule of rules" class="d-flex justify-content-between align-items-center w-100 rule-line">
      <div class="d-flex align-items-center justify-content-start" style="gap: 10px;">
        <i *ngIf="!deletingRules.includes(rule.id)" class="fal fa-trash" (click)="deleteRule(rule)"></i>
        <i *ngIf="deletingRules.includes(rule.id)" class="fal fa-times" (click)="deleteRule(rule)"></i>
        <p class="rule-name" (click)="editRule(rule)"
          [style]="deletingRules.includes(rule.id) ? 'text-decoration: line-through; pointer-events: none' : ''">
          {{translateType(rule.restriction_type)}}<span *ngFor="let app of jsonParser(rule.apps)"> -
            {{app}}</span></p>
      </div>
      <i [class]="deletingRules.length>0 ? 'disabled' : ''" class="fal fa-arrow-right" (click)="editRule(rule)"></i>
    </div>
  </div>
  <div *ngIf="!loading && editing==true" class="limit-wrapper">
    <div class="subheader d-flex justify-content-start align-items-center">
      <i class="fal fa-arrow-left" style="cursor: pointer;" (click)="back()"></i>
      <p>Selecciona las APPs que quieres limitar: <span><i class="fal fa-circle-info"
            matTooltip="Elige al menos una aplicación de la cual quieras limitar el uso"></i></span>
      </p>
    </div>
    <div class="apps-checks-wrapper">
      <mat-checkbox *ngFor="let app of apps" [checked]="selectedApps.includes(app)"
        (change)="changeApp($event, app)">{{capitalize(app.toLowerCase())}}</mat-checkbox>
      <p class="hint-app">*Selecciona al menos una app</p>
    </div>
    <div class="subheader" style="border-top: 1px solid var(--color-tertiary);">
      <i></i>
      <p>Cuando las quieres limitar: <span><i class="fal fa-circle-info"
            matTooltip="Selecciona una opción para limitar el uso de las aplicaciones seleccionadas. Los días u horas seleccionadas serán en los que el uso de la aplicación quede restringido"></i></span>
      </p>
    </div>
    <div class="radio-wrapper">
      <mat-radio-group class="d-flex flex-column" aria-labelledby="example-radio-group-label" [(ngModel)]="limitOption"
        (ngModelChange)="radioValueChanged()">
        <mat-radio-button value="DENY">No permitir nunca</mat-radio-button>
        <mat-radio-button value="L-V">Limitar de Lunes a Viernes</mat-radio-button>
        <mat-radio-button value="OFFICE">Bloquear en horario oficina (8:00h a
          18:00h)</mat-radio-button>
        <mat-radio-button value="DAYS">Limitar por días
          concretos</mat-radio-button>
        <div *ngIf="limitOption=='DAYS'" style="margin-left: 6px;">
          <mat-checkbox [(ngModel)]="weekdays[0]">Lunes</mat-checkbox>
          <mat-checkbox [(ngModel)]="weekdays[1]">Martes</mat-checkbox>
          <mat-checkbox [(ngModel)]="weekdays[2]">Miércoles</mat-checkbox>
          <mat-checkbox [(ngModel)]="weekdays[3]">Jueves</mat-checkbox>
          <mat-checkbox [(ngModel)]="weekdays[4]">Viernes</mat-checkbox>
          <mat-checkbox [(ngModel)]="weekdays[5]">Sábados</mat-checkbox>
          <mat-checkbox [(ngModel)]="weekdays[6]">Domingos</mat-checkbox>
        </div>
        <mat-radio-button value="RANGES">Restringir por franjas
          horarias</mat-radio-button>
        <div *ngIf="limitOption=='RANGES'" style="margin-left: 6px;">
          <mat-checkbox [(ngModel)]="timeZones[0]">Mañanas: (6:00h a 14:00h)</mat-checkbox>
          <mat-checkbox [(ngModel)]="timeZones[1]">Tardes: (14:00h a 22:00h)</mat-checkbox>
          <mat-checkbox [(ngModel)]="timeZones[2]">Noches: (22:00h a 6:00h)</mat-checkbox>
        </div>
        <mat-radio-button value="CUSTOM">Seleccionar horas de
          restricción</mat-radio-button>
        <div class="timetable-wrapper" *ngIf="limitOption=='CUSTOM'">
          <div class="timetable">
            <div class="headline">
              <div class="blank"></div>
              <div class="day" *ngFor="let day of days">{{truncate3chars(day)}}</div>
            </div>
            <div class="line" *ngFor="let hour of hours">
              <div class="hour">{{hour}}</div>
              <div *ngFor="let day of days" class="selectable" (click)="selectTimetable({day: day, hour: hour})"
                [ngClass]="isHourSelected({day: day, hour: hour})===true ? 'selected' : ''"></div>
            </div>
          </div>
        </div>
      </mat-radio-group>
      <p class="hint-app">*Selecciona una restricción</p>
    </div>
  </div>
</div>