import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar-button',
  templateUrl: './sidebar-button.component.html',
  styleUrl: './sidebar-button.component.scss'
})
export class SidebarButtonComponent {

  @Input() icon = '';
  @Input() text = '';
  @Input() number = '';
  @Input() selected = false;
}
