<div class="paginator-container" *ngIf="arrayPages && totalPages>5">
  <i class="far fa-chevron-left hover-link" [ngClass]="actualPage===1 ? 'disable-arrow' : ''"
    (click)="previousPage()"></i>
  <span class="number hover-link" [ngClass]="actualPage===1 ? 'selected' : 'unselected' "
    (click)="changePage(1)">1</span>
  <span class="number unselected ellipsis" *ngIf="actualPage!==1  || actualPage===totalPages">...</span>
  <span class="number hover-link" *ngFor="let page of arrayPages"
    [ngClass]="actualPage===page ? 'selected' : 'unselected' " (click)="changePage(page)">{{page}}</span>
  <span class="number unselected ellipsis" *ngIf="actualPage==1  || actualPage!==totalPages">...</span>
  <span class="number hover-link" [ngClass]="actualPage===totalPages ? 'selected' : 'unselected' "
    (click)="changePage(totalPages)">{{totalPages}}</span>
  <i class="far fa-chevron-right hover-link" [ngClass]="actualPage===totalPages ? 'disable-arrow' : ''"
    (click)="nextPage()"></i>
</div>
<div class="paginator-container" *ngIf="arrayPages && totalPages<=5">
  <i class="far fa-chevron-left hover-link" [ngClass]="actualPage===1 ? 'disable-arrow' : ''"
    (click)="previousPage()"></i>

  <span class="number hover-link" *ngFor="let page of arrayPages"
    [ngClass]="actualPage===page ? 'selected' : 'unselected' " (click)="changePage(page)">{{page}}</span>

  <i class="far fa-chevron-right hover-link" [ngClass]="actualPage===totalPages ? 'disable-arrow' : ''"
    (click)="nextPage()"></i>
</div>