<div class="add-to-more-dialog-wrapper d-flex justify-content-center align-items-center flex-column">
  <div class="content">
    <div class="d-flex justify-content-between align-items-center w-100" style="margin-bottom: 24px;">
      <p class="title" *ngIf="mode=='fiber'">Selecciona otras direcciones</p>
      <p class="title" *ngIf="mode=='lines'">Selecciona otras líneas</p>
      <i class="fal fa-times close-icon" (click)="close()"></i>
    </div>
    <div *ngIf="loading" class="loading-wrapper" style="flex: 1 1 auto;">
      <mat-spinner></mat-spinner>
      <p>Cargando...</p>
    </div>
    <div class="scroll-list">
      <div *ngFor="let item of data" class="d-flex align-items-center" style="gap: 12px;">
        <mat-checkbox *ngIf="mode=='fiber'" (click)="addDirection(item.ip)"
          [checked]="selectedDirections.includes(item.ip)"></mat-checkbox>
        <mat-checkbox *ngIf="mode=='lines'" (click)="addDirection(item.telefono)"
          [checked]="selectedDirections.includes(item.telefono)"></mat-checkbox>
        <p *ngIf="mode=='fiber'">{{item.ip}}</p>
        <p *ngIf="mode=='lines'">{{item.telefono}}</p>
      </div>
    </div>
  </div>
  <app-accent-button [ngClass]="loadingApi==true ? 'disabled' : ''" (click)="confirm()" text="Confirmar"
    [selected]="true"></app-accent-button>
</div>