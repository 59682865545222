import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { fass } from '@fortawesome/sharp-solid-svg-icons'

import { DirectivesModule } from './directives/directives.module';
import { ComponentsModule } from './components/components.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { LoginComponent } from './pages/login/login.component';
import { PanelRouterComponent } from './pages/panel-router/panel-router.component';
import { MyLinesComponent } from './pages/my-lines/my-lines.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './extra-modules/material.module';
import { ConfigureFiberDialogComponent } from './dialogs/configure-fiber-dialog/configure-fiber-dialog.component';
import { SuccessDialogComponent } from './dialogs/success-dialog/success-dialog.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SettingLineComponent } from './pages/settings/components/setting-line/setting-line.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ClientsComponent } from './pages/clients/clients.component';
import { ConfigureTelephoneLineComponent } from './dialogs/configure-telephone-line/configure-telephone-line.component';
import { DateAdapter, MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './custom-date-adapter';
import { AdminSettingsComponent } from './dialogs/admin-settings/admin-settings.component';
import { RulesEditorComponent } from './components/rules-editor/rules-editor.component';
import { AddToMoreDialogComponent } from './dialogs/add-to-more-dialog/add-to-more-dialog.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PanelRouterComponent,
    MyLinesComponent,
    ConfigureFiberDialogComponent,
    SuccessDialogComponent,
    SettingsComponent,
    SettingLineComponent,
    ClientsComponent,
    ConfigureTelephoneLineComponent,
    AdminSettingsComponent,
    AddToMoreDialogComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    ComponentsModule,
    DirectivesModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule

  ],
  providers: [
    provideClientHydration(),
    provideAnimationsAsync(),
    provideNativeDateAdapter(),
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    RulesEditorComponent,
    SettingsComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fal, fat, fass)
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
