import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../app-settings';
import { map } from 'rxjs';
import { error } from 'console';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(user: string, password: string, redirect: boolean) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(AppSettings.API_URI + '/login', { user: user, password: password, redirect: redirect }, formHeaders).pipe(
      map(jwt => {
        if (jwt.access_token) {
          localStorage.setItem('jwt_token', jwt.access_token);
        }
        return jwt;
      })
    );
  }

  getUserMe() {
    return this.http.get<any>(AppSettings.API_URI + '/me').pipe(
      map(data => {
        return data;
      }, (error: any) => {
        return false;
      })
    );
  }

  logout() {
    return this.http.get<any>(AppSettings.API_URI + '/logout').pipe(
      map(data => {
        localStorage.clear();
        return data;
      })
    );
  }

  // refresh() {
  //   return this.http.get<any>(AppSettings.API_URI + '/refresh').pipe(
  //     map(jwt => {
  //       if (jwt.access_token) {
  //         localStorage.setItem('jwt_token', jwt.access_token);
  //       }
  //       return jwt;
  //     })
  //   );
  // }

  refreshToken() {
    return this.http.get<any>(AppSettings.API_URI + '/refresh').pipe(
      map(jwt => {
        localStorage.setItem('jwt_token', jwt.access_token);
        return jwt;
      })
    );
  }
}
