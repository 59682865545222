<div class="my-lines-wrapper">
  <div class="mobile-title">
    <p>Mis IP</p>
  </div>
  <div class="d-flex justify-content-between align-items-center w-100">
    <div style="max-width: 620px; width: 620px;">
      <app-searchbar [ngClass]="loadingApi==true ? 'disabled' : ''" (emitSearch)="searchData($event)"></app-searchbar>
    </div>
    <app-welcome-user></app-welcome-user>
  </div>
  <div class="content-wrapper">
    <div class="buttons-bar d-flex justify-content-between align-items-center w-100">
      <div class="d-flex align-items-center" style="gap: 8px;">
        <app-accent-button [ngClass]="loadingApi==true ? 'disabled' : ''" text="Fibra óptica"
          [selected]="currentView=='fiber'" (click)="changeView('fiber')"></app-accent-button>
        <app-accent-button [ngClass]="loadingApi==true ? 'disabled' : ''" text="Líneas telefónicas"
          [selected]="currentView=='lines'" (click)="changeView('lines')"></app-accent-button>
      </div>
      <div class="arrows-wrapper d-flex align-items-center">
        <i [ngClass]="loadingApi==true || actualPage==1 ? 'disabled' : 'enabled'" class="far fa-chevron-left"
          (click)="changePage(actualPage-1)"></i>
        <i [ngClass]="loadingApi==true || actualPage==totalPages ? 'disabled' : 'enabled'" class="far fa-chevron-right"
          (click)="changePage(actualPage+1)"></i>
      </div>
    </div>
    <div class="table-wrapper table-scroll">
      <table *ngIf="loading" mat-table class="data-table" style="margin-bottom: 0px;">
        <ng-container matColumnDef="num">
          <th mat-header-cell *matHeaderCellDef> {{getHeader()}} </th>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Acciones </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div class="error-message">
        <p>{{errorMessage}}</p>
      </div>
      <div class="error-message" *ngIf="!loading && data.length===0">
        <p>No hay resultados</p>
      </div>

      <div *ngIf="loading" class="loading-wrapper">
        <mat-spinner></mat-spinner>
        <p>Cargando...</p>
      </div>

      <table *ngIf="!loading && data.length>0" mat-table [dataSource]="data" class="data-table">
        <ng-container matColumnDef="num">
          <th mat-header-cell *matHeaderCellDef> {{getHeader()}}</th>
          <td mat-cell *matCellDef="let element" class="bold-cell"> {{getData(element)}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Acciones </th>
          <td mat-cell *matCellDef="let element" class="regular-cell" style="min-width: 135px;"
            (click)="openConfiguration(element)">
            <span>Configurar</span> <i class="fal fa-edit" style="margin-left: 10px;"></i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div *ngIf="!loading && data.length>0" class="d-flex justify-content-center align-items-center w-100"
      style="margin-top: auto;flex: 0;">
      <app-paginator style="margin-top: var(--space-blocks);" class="paddings-bottom" [totalPages]="totalPages"
        [actualPage]="actualPage" (newPageEvent)="changePage($event)"></app-paginator>
    </div>

  </div>
</div>