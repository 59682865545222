<div class="sidebar-wrapper d-flex flex-column align-items-center">
  <img class="logo" src="../../../assets/images/logo.png" alt="logo">
  <p *ngIf="admin && !user" class="label">Busca un cliente para ver más opciones</p>

  <app-sidebar-button [ngClass]="loadingApi==true ? 'disabled' : ''" *ngIf="admin && user"
    text="Seleccionar otro cliente" icon="arrow-left-to-line" (click)="logoutAsUser()"></app-sidebar-button>
  <p *ngIf="admin && user" class="client-label">Cliente:</p>
  <div *ngIf="admin && user && adminAsUser" class="client-info d-flex flex-column">
    <div class="line">
      <i class="fal fa-user"></i>
      <p>{{adminAsUser.name.toUpperCase()}}</p>
    </div>
    <div class="line">
      <i class="fal fa-phone"></i>
      <p>{{adminAsUser.contactPhone}}</p>
    </div>
    <div class="line">
      <i class="fal fa-id-card"></i>
      <p>{{adminAsUser.fiscalId}}</p>
    </div>
  </div>
  <app-sidebar-button [ngClass]="loadingApi==true ? 'disabled' : ''" *ngIf="user" text="Mis líneas" icon="table-list"
    [selected]="router.url==='/mis-lineas'" (click)="goTo('/mis-lineas')"
    style="margin-bottom: 11px;"></app-sidebar-button>
  <app-sidebar-button [ngClass]="loadingApi==true ? 'disabled' : ''" *ngIf="user" text="Configuraciones" icon="sliders"
    [selected]="router.url==='/configuraciones'" (click)="goTo('/configuraciones')"></app-sidebar-button>
  <app-sidebar-button [ngClass]="loadingApi==true ? 'disabled' : ''" *ngIf="admin && !user" text="Listado de clientes"
    icon="list" [selected]="router.url==='/clientes'" style="margin-bottom: 11px;"
    (click)="goTo('/clientes')"></app-sidebar-button>
  <app-sidebar-button [ngClass]="loadingApi==true ? 'disabled' : ''" *ngIf="admin && !user" text="Configuraciones"
    icon="sliders" [selected]="router.url==='/configuraciones'" style="margin-bottom: 11px;"
    (click)="goTo('/configuraciones')"></app-sidebar-button>
  <app-sidebar-button [ngClass]="loadingApi==true ? 'disabled' : ''" *ngIf="admin && !user" text="Ajustes" icon="gear"
    [selected]="false" (click)="openAdminConfig()"></app-sidebar-button>
  <app-sidebar-button [ngClass]="loadingApi==true ? 'disabled' : ''" *ngIf="(admin && !user) || (!admin && user)"
    text="Salir" icon="arrow-left-to-line" (click)="logout()"></app-sidebar-button>
  <app-sidebar-button [ngClass]="loadingApi==true ? 'disabled' : ''" (click)="logoutAsUser()" *ngIf="admin && user"
    text="Volver" icon="arrow-left-to-line"></app-sidebar-button>
</div>