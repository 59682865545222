import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrl: './paginator.component.scss'
})
export class PaginatorComponent {
  @Input() actualPage: number = 1;
  @Input() totalPages: number = 1;
  @Output() newPageEvent = new EventEmitter<number>();
  arrayPages: number[] = [];
  constructor() {

  }

  ngOnInit() {
    this.loadPageArray();
  }



  changePage(number: number) {
    this.actualPage = number;
    this.loadPageArray();
    this.newPageEvent.emit(this.actualPage);

  }
  previousPage() {
    this.actualPage = this.actualPage - 1;
    this.loadPageArray();
    this.newPageEvent.emit(this.actualPage);

  }
  nextPage() {
    this.actualPage = this.actualPage + 1;
    this.loadPageArray();
    this.newPageEvent.emit(this.actualPage);
  }

  loadPageArray() {
    this.arrayPages = [];
    if (this.totalPages > 5) {
      switch (this.actualPage) {
        case 1:
          this.arrayPages = [2, 3];
          break;
        case this.totalPages:
          for (var i = 1; i < 3; i++)
            this.arrayPages.unshift(this.totalPages - i);
          break;
        default:
          this.arrayPages = [this.actualPage]
          break;
      }
    } else {

      for (var i = 1; i <= this.totalPages; i++)
        this.arrayPages.push(i);

    }


  }
}
