import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MetatagsService } from './services/metatags.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'masip-front';
  constructor(private translate: TranslateService, private meta: Meta, private metaTagsService: MetatagsService, protected router: Router) {
    this.metaTagsService.updateMetadata({ "title": "MasIP" });
    const lang = translate.getBrowserLang();
    translate.setDefaultLang(lang ? lang : "es");
    translate.use(lang ? lang : "es");
    console.log(this.router.url);

  }
}
