import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { RulesEditorComponent } from '../../components/rules-editor/rules-editor.component';
import { AddToMoreDialogComponent } from '../add-to-more-dialog/add-to-more-dialog.component';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { MasipService } from '../../services/masip.service';

@Component({
  selector: 'app-configure-fiber-dialog',
  templateUrl: './configure-fiber-dialog.component.html',
  styleUrl: './configure-fiber-dialog.component.scss'
})
export class ConfigureFiberDialogComponent {

  ip: string = '';
  rule: any;
  loadingApi: boolean = false;
  pendingInQueue: number = 0;

  @ViewChild(RulesEditorComponent)
  protected rulesEditor!: RulesEditorComponent;

  constructor(private dialogRef: MatDialogRef<ConfigureFiberDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private authService: AuthService, private dialog: MatDialog, private masipService: MasipService) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.masipService.apiLoadingStorage.subscribe(resp => {
      this.loadingApi = resp;
    })
    this.ip = this.data.ip;
    this.masipService.getPendingInQueue(this.ip).subscribe(resp => {
      this.pendingInQueue = resp;
    })
  }


  close() {
    this.dialogRef.close();
  }



  checkRuleValid(): boolean {
    if (this.rulesEditor) {
      if (this.rulesEditor.deletingRules.length > 0) {
        return true;
      }
      if ((!this.rulesEditor.editing || (this.rulesEditor.editing && this.rulesEditor.selectedApps.length > 0 && this.rulesEditor.limitOption != ''))) {
        if ((this.rulesEditor.limitOption == 'DAYS' && this.rulesEditor.weekdays.every(e => e == false)) || (this.rulesEditor.limitOption == 'RANGES' && this.rulesEditor.timeZones.every(e => e == false)) || (this.rulesEditor.limitOption == 'CUSTOM' && this.rulesEditor.selectedHours.length == 0)) {
          return false;
        }
        return true;
      }
      return false;
    }
    return true;
  }
  deleteRule(toDelete: any) {
    if (toDelete == null) {
      this.rule = undefined;
    }
    var userFiscalId = '';
    if (localStorage.getItem('accessAsUser') !== null) {
      userFiscalId = String(localStorage.getItem('accessAsUser') || '');
    }
    this.rule = {
      id: toDelete.id,
      fiscal_id: userFiscalId,
      address: this.ip,
      type: 'fiber',
      apps: [],
      restriction_type: null,
    }
  }


  finish() {
    var userFiscalId = '';
    if (localStorage.getItem('accessAsUser') !== null) {
      userFiscalId = String(localStorage.getItem('accessAsUser') || '');
    }
    if (this.rulesEditor.deletingRules.length > 0) {
      var apiCallsStatus: any[] = [];
      this.masipService.setApiLoadingGlobal(true);
      var aux = {
        id: 0,
        fiscal_id: userFiscalId,
        address: this.ip,
        type: 'fiber',
        apps: [],
        restriction_type: null,
      }
      this.rulesEditor.deletingRules.forEach(element => {
        aux.id = element;
        apiCallsStatus.push({ id: element, status: false });
        this.masipService.postRulesQueue(aux).subscribe(resp => {
          let index = apiCallsStatus.findIndex(e => e.id == element);
          apiCallsStatus[index].status = true;
        })
      });

      var interval = setInterval(() => {
        if (apiCallsStatus.every(v => v.status === true)) {
          clearInterval(interval);
          this.masipService.setApiLoadingGlobal(false);
          this.dialogRef.close();
          const dialogRef = this.dialog.open(SuccessDialogComponent, {
            panelClass: 'success-dialog',
          });
          return dialogRef.afterClosed();
        }
        return;
      }, 1000);
    } else {
      var days: string[] = [];
      if (this.rulesEditor.limitOption == 'DAYS') {
        let index = 0;
        this.rulesEditor.weekdays.forEach(element => {
          if (element) {
            days.push(this.rulesEditor.selectionDays[index]);
          }
          index++;
        });
      }
      var ranges = [];
      if (this.rulesEditor.limitOption == 'RANGES') {
        if (this.rulesEditor.timeZones[0] == true) {
          ranges.push('06-14');
        }
        if (this.rulesEditor.timeZones[1] == true) {
          ranges.push('14-22');
        }
        if (this.rulesEditor.timeZones[2] == true) {
          ranges.push('22-06');
        }
      }
      switch (this.rulesEditor.limitOption) {
        case 'DAYS':

          this.rule = {
            fiscal_id: userFiscalId,
            address: this.ip,
            type: 'fiber',
            apps: this.rulesEditor.selectedApps,
            restriction_type: this.rulesEditor.limitOption,
            days: days
          }
          break;
        case 'RANGES':

          this.rule = {
            fiscal_id: userFiscalId,
            address: this.ip,
            type: 'fiber',
            apps: this.rulesEditor.selectedApps,
            restriction_type: this.rulesEditor.limitOption,
            ranges: ranges
          }
          break;
        case 'CUSTOM':

          this.rule = {
            fiscal_id: userFiscalId,
            address: this.ip,
            type: 'fiber',
            apps: this.rulesEditor.selectedApps,
            restriction_type: this.rulesEditor.limitOption,
            custom: this.rulesEditor.selectedHours
          }
          break;

        default:
          if (this.rule && this.rule.limitOption == null) {
            break;
          }
          this.rule = {
            fiscal_id: userFiscalId,
            address: this.ip,
            type: 'fiber',
            apps: this.rulesEditor.selectedApps,
            restriction_type: this.rulesEditor.limitOption,
          }
          break;
      }
      if (this.rulesEditor.editRuleId != 0) {
        this.rule.id = this.rulesEditor.editRuleId;
      }
      this.masipService.setApiLoadingGlobal(true);
      this.masipService.postRulesQueue(this.rule).subscribe(resp => {
        this.masipService.setApiLoadingGlobal(false);
        this.dialogRef.close();
        const dialogRef = this.dialog.open(SuccessDialogComponent, {
          panelClass: 'success-dialog',
        });
        return dialogRef.afterClosed();
      })
    }


  }

  openAddToMore() {
    const dialogRef = this.dialog.open(AddToMoreDialogComponent, {
      panelClass: 'add-more-dialog',
      data: {
        mode: 'fiber',
        rule: this.rule,
        selectedDirection: this.ip,
        deletedRules: this.rulesEditor.deletingRules,
        ip: this.ip,

      }
    });

    return dialogRef.afterClosed().subscribe(result => {
      if (result === 'close') {
        this.close();
      }
    });

  }

}
