<div class="login-wrapper d-flex justify-content-center align-items-center">

  <div class="login-form-wrapper d-flex flex-column align-items-center">
    <img class="logo" src="../../../assets/images/logo.png" alt="logo">

    <form [formGroup]="loginForm" class="w-100">
      <div class="input-login d-flex justify-content-start align-items-center">
        <i class="fal fa-circle-user icon"></i>
        <input formControlName="user" type="text" placeholder="Usuario">
      </div>
      <div class="input-login d-flex justify-content-start align-items-center">
        <i class="fal fa-lock icon"></i>
        <input formControlName="password" [type]="showingPassword ? 'text' : 'password'" placeholder="Contraseña">
        <i class="fal fa-eye pass-eye" *ngIf="!showingPassword" (click)="showingPassword = !showingPassword"></i>
        <i class="fal fa-eye-slash pass-eye" *ngIf="showingPassword" (click)="showingPassword = !showingPassword"></i>
      </div>
    </form>

    <app-accent-button text="Entrar" [selected]="true" class="button" (click)="doLogin()"></app-accent-button>

    <p class="error-message">{{errorMessage}}</p>
  </div>
</div>