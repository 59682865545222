import { Component } from '@angular/core';
import { MasipService } from '../../services/masip.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrl: './admin-settings.component.scss'
})
export class AdminSettingsComponent {

  status: string = '';
  requests_per_minute: string = '';
  errorMessage: string = '';

  constructor(private masipService: MasipService, private dialogRef: MatDialogRef<AdminSettingsComponent>) { }

  ngOnInit() {
    this.masipService.getConfigAdmin().subscribe(resp => {
      this.status = resp.data.find((e: { name: string; }) => e.name == 'status').value;
      this.requests_per_minute = resp.data.find((e: { name: string; }) => e.name == 'requests_per_minute').value;
    })
  }

  changeStatus(event: any) {
    this.status = event;
  }


  changeRequests(event: any) {
    this.requests_per_minute = event.target.value;
  }

  confirm() {
    this.masipService.postConfigAdmin({ status: this.status, requests_per_minute: this.requests_per_minute }).subscribe(resp => {
      this.errorMessage = '';
      this.close();
    }, error => {
      this.errorMessage = 'Se ha producido un error';
    });
  }

  close() {
    this.dialogRef.close();
  }
}
