import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MasipService } from '../../services/masip.service';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { RulesEditorComponent } from '../../components/rules-editor/rules-editor.component';
import { AddToMoreDialogComponent } from '../add-to-more-dialog/add-to-more-dialog.component';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';

@Component({
  selector: 'app-configure-telephone-line',
  templateUrl: './configure-telephone-line.component.html',
  styleUrl: './configure-telephone-line.component.scss'
})
export class ConfigureTelephoneLineComponent {

  telephone: string = '';
  currentTab: string = 'calls';

  settings: any[] = [];
  associatedIp: string = '';

  displayedSettings: string[] = [];

  withActive: string[] = ['Boic', 'CountryFixedOGCallsBarred', 'Clir', 'CountryMobileOGCallsBarred', 'AireBarrado118', 'AireBarrado118_N2', 'AireBarrado118_N3', 'AireBarrado901', 'AireBarrado902', 'AireBarrado905', 'AireBarrado907', 'AireBarradoPremium', 'AireBarradoPremiumPlus', 'AireBarrado70X', 'AireBarrado_m2m', 'CW', 'Hold', 'ShortMessageMT-PP', 'ShortMessageMO-PP', 'Clip', 'MultiPTY', 'Lca', 'PS', 'Telephony', 'Cfu', 'Cfb', 'Cfnrc', 'Cfnry', 'RoamingOutsidePLMNICountryIC-CallsBarred', 'LTE'];
  withSelectPhone: string[] = ['Cfb', 'Cfnrc'];
  withSelectRoaming: string[] = ['RoamingOutsidePLMN-CountryBarred', 'RoamerAccessToHPLMN-AP-Barred'];
  withSelectTime: string[] = ['Cfnry'];
  withSelectShow: string[] = ['Clir'];
  withInput: string[] = ['Cfu', 'IMEI', 'Cfnry'];

  pendingInQueue: number = 0;

  whitelist: any[] = [];
  blacklist: any[] = [];

  rules: any[] = [];
  rule: any;

  apiCallsStatus: boolean[] = [false, false, false];

  loadingApi: boolean = false;
  initLoadStatus: boolean[] = [false, false, false];

  loading: boolean = false;
  errorBlackWhitelist: boolean = false;
  date = new FormControl(new Date());
  @ViewChild(RulesEditorComponent)
  protected rulesEditor!: RulesEditorComponent;
  constructor(private dialogRef: MatDialogRef<ConfigureTelephoneLineComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private masipService: MasipService, private authService: AuthService, private dialog: MatDialog) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.masipService.setApiLoading(true);
    this.masipService.apiLoadingStorage.subscribe(resp => {
      this.loadingApi = resp;
    })
    this.changeTab('calls');
    this.telephone = this.data.telephone;
    this.loading = true;
    this.getLineServices();
    this.getBlacklist();
    this.getWhitelist();
    this.masipService.getPendingInQueue(this.telephone).subscribe(resp => {
      this.pendingInQueue = resp;
    })

    var interval = setInterval(() => {
      if (this.initLoadStatus.every(v => v === true)) {
        this.masipService.setApiLoading(false);
        clearInterval(interval);
      }
      // if (this.initLoadStatus.every(v => v === true && this.associatedIp != '')) {
      //   console.log(this.loadingApi);

      //   if (this.loadingApi == false) {
      //     clearInterval(interval);
      //   }
      // }
      // if (!this.initLoadStatus.every(v => v === true && this.associatedIp != '')) {
      //   if (this.loadingApi == false) {
      //     this.masipService.setApiLoading(true);
      //   }
      //   if (this.initLoadStatus.every(v => v === true)) {
      //     this.masipService.setApiLoading(false);
      //     clearInterval(interval);
      //   }
      // }
    }, 500);
  }

  close() {
    this.dialogRef.close();
  }

  saveChanges() {
    this.masipService.setApiLoadingGlobal(true);
    var fiscalId = '';
    if (localStorage.getItem('accessAsUser') !== null) {
      fiscalId = String(localStorage.getItem('accessAsUser') || '');
    }
    this.masipService.postConfigLineQueue({ fiscal_id: fiscalId, phoneNumber: this.telephone, services: this.settings }).subscribe(resp => {
      this.apiCallsStatus[0] = true;
    });
    this.masipService.postWhitelistQueue({ fiscal_id: fiscalId, phone_line: this.telephone, phonesNumbers: this.whitelist }).subscribe(resp => {
      this.apiCallsStatus[1] = true;
    });
    this.masipService.postBlacklistQueue({ fiscal_id: fiscalId, phone_line: this.telephone, phonesNumbers: this.blacklist }).subscribe(resp => {
      this.apiCallsStatus[2] = true;
    });
    if (this.associatedIp != '') {
      this.apiCallsStatus.push(false);
      this.sendRules();
    }
    var interval = setInterval(() => {
      if (this.apiCallsStatus.every(v => v === true)) {
        this.masipService.setApiLoadingGlobal(false);
        clearInterval(interval);
        this.dialogRef.close();
        const dialogRef = this.dialog.open(SuccessDialogComponent, {
          panelClass: 'success-dialog',
        });
      }
    }, 1000);

  }

  dateToLocalISO(date: any) {
    const off = date.getTimezoneOffset()
    const absoff = Math.abs(off)
    return (new Date(date.getTime() - off * 60 * 1000).toISOString().substr(0, 23) +
      (off > 0 ? '-' : '+') +
      Math.floor(absoff / 60).toFixed(0).padStart(2, '0') + ':' +
      (absoff % 60).toString().padStart(2, '0'))
  }

  resetRule() {
    this.rule = undefined;
  }

  checkRuleValid(): boolean {
    if (this.rulesEditor) {
      if (this.rulesEditor.deletingRules.length > 0) {
        return true;
      }
      if ((!this.rulesEditor.editing || (this.rulesEditor.editing && this.rulesEditor.selectedApps.length > 0 && this.rulesEditor.limitOption != ''))) {
        if ((this.rulesEditor.limitOption == 'DAYS' && this.rulesEditor.weekdays.every(e => e == false)) || (this.rulesEditor.limitOption == 'RANGES' && this.rulesEditor.timeZones.every(e => e == false)) || (this.rulesEditor.limitOption == 'CUSTOM' && this.rulesEditor.selectedHours.length == 0)) {
          return false;
        }
        return true;
      }
      return false;
    }
    return true;
  }

  changeTab(tab: string) {
    this.errorBlackWhitelist = false;
    if (this.currentTab == 'rules' && this.associatedIp != '') {
      this.buildRuleObject();
      if (this.rule.apps == '[]' && this.rule.restriction_type == '') {
        this.rule = undefined;
      }
    }
    this.currentTab = tab;
    switch (tab) {
      case 'calls':
        this.displayedSettings = ['Boic', 'CountryFixedOGCallsBarred', 'CountryMobileOGCallsBarred', 'AireBarrado118', 'AireBarrado118_N2', 'AireBarrado118_N3', 'AireBarrado901', 'AireBarrado902', 'AireBarrado905', 'AireBarrado907', 'AireBarradoPremium', 'AireBarradoPremiumPlus', 'AireBarrado70X', 'AireBarrado_m2m', 'CW', 'Hold', 'ShortMessageMT-PP', 'ShortMessageMO-PP', 'Clip', 'Clir', 'MultiPTY', 'Lca'];
        break;
      case 'forwarding':
        this.displayedSettings = ['Cfu', 'Cfb', 'Cfnrc', 'Cfnry'];
        break;
      case 'internet':
        this.displayedSettings = ['RoamingOutsidePLMN-CountryBarred', 'RoamerAccessToHPLMN-AP-Barred', 'PS', 'Telephony', 'LTE', 'VLR', 'LastUpdateLocation', 'IMEI'];
        break;
      case 'whitelist':
      case 'blacklist':
        this.displayedSettings = [];
        break;
      case 'rules':
        if (this.associatedIp != '' && this.rule && (this.rule.apps != '[]' && this.rule.restriction_type != null)) {
          setTimeout(() => {
            this.rulesEditor.editRule(this.rule);
          }, 100);
        }
        this.displayedSettings = [];
        break;
      default:
        break;
    }
  }

  changeActive(index: number) {
    if (this.settings[index].active) {
      switch (this.settings[index].active) {
        case 'true':
          this.settings[index].active = 'false';
          // this.settings[index].extString = null;
          // this.settings[index].extNumber = null;
          break;
        case 'false':
          this.settings[index].active = 'true';
          break;
        default:
          break;
      }
    }
  }

  extStringChange(index: number, event: any) {
    this.settings[index].extString = event;
  }

  extStringChangeInput(index: number, event: any) {
    this.settings[index].extString = event.target.value;
  }


  extNumberChange(index: number, event: any) {
    this.settings[index].extNumber = event;
  }

  countryChange(index: number, event: any) {
    this.settings[index].country = event.target.value;
  }

  networkChange(index: number, event: any) {
    this.settings[index].network_name = event.target.value;
  }

  addToWhitelist() {
    var number = (<HTMLInputElement>document.getElementById("whitelist-input")).value;
    if (!this.whitelist.includes(number) && number.trim() != '') {
      this.whitelist.push(number);
      (<HTMLInputElement>document.getElementById("whitelist-input")).value = '';
    } else {
      this.errorBlackWhitelist = true;
    }

  }

  deleteFromWhitelist(phone: string) {
    const index = this.whitelist.indexOf(phone);
    if (index > -1) {
      this.whitelist.splice(index, 1);
    }
  }

  addToBlacklist() {
    var number = (<HTMLInputElement>document.getElementById("blacklist-input")).value;
    if (!this.blacklist.includes(number) && number.trim() != '') {
      this.blacklist.push(number);
      (<HTMLInputElement>document.getElementById("blacklist-input")).value = '';
    } else {
      this.errorBlackWhitelist = true;
    }
  }

  deleteFromBlacklist(phone: string) {
    const index = this.blacklist.indexOf(phone);
    if (index > -1) {
      this.blacklist.splice(index, 1);
    }
  }

  getLineServices() {
    this.masipService.getLineServices(this.telephone).subscribe(resp => {
      this.settings = resp.data;
      var ipData = this.settings.find(e => e.featureID == 'IPv4EndUserAddress');
      if (ipData.active == 'true' && ipData.extString != null) {
        this.associatedIp = ipData.extString;
      }
      var ipData = this.settings.find(e => e.featureID == 'LastUpdateLocation');
      if (ipData.extString != null) {
        this.date = new FormControl(new Date(ipData.extString));
      }
      this.loading = false;
      this.initLoadStatus[0] = true;
    }, error => {

      this.loading = false;
      this.initLoadStatus[0] = true;
    })
  }

  getWhitelist() {
    this.masipService.getWhitelist(this.telephone).subscribe(resp => {
      resp.data.forEach((element: { phone_number: any; }) => {
        this.whitelist.push(element.phone_number)
      });
      this.loading = false;
      this.initLoadStatus[2] = true;
    }, error => {

      this.loading = false;
      this.initLoadStatus[2] = true;
    })
  }

  getBlacklist() {
    this.masipService.getBlacklist(this.telephone).subscribe(resp => {
      resp.data.forEach((element: { phone_number: any; }) => {
        this.blacklist.push(element.phone_number)
      });
      this.loading = false;
      this.initLoadStatus[1] = true;
    }, error => {

      this.loading = false;
      this.initLoadStatus[1] = true;
    })
  }

  hasActive(item: any) {
    if (item.featureID) {

    }
  }

  deleteRule(toDelete: any) {
    if (toDelete == null) {
      this.rule = undefined;
    }
    var userFiscalId = '';
    if (localStorage.getItem('accessAsUser') !== null) {
      userFiscalId = String(localStorage.getItem('accessAsUser') || '');
    }
    this.rule = {
      id: toDelete.id,
      fiscal_id: userFiscalId,
      address: this.associatedIp,
      type: 'line',
      apps: [],
      restriction_type: null,
    }
  }


  sendRules() {
    if (this.rulesEditor.deletingRules.length > 0) {
      var userFiscalId = '';
      if (localStorage.getItem('accessAsUser') !== null) {
        userFiscalId = String(localStorage.getItem('accessAsUser') || '');
      }
      var apiCallsStatus: any[] = [];
      this.masipService.setApiLoadingGlobal(true);
      var aux = {
        id: 0,
        fiscal_id: userFiscalId,
        address: this.associatedIp,
        type: 'line',
        apps: [],
        restriction_type: null,
      }
      this.rulesEditor.deletingRules.forEach(element => {
        aux.id = element;
        apiCallsStatus.push({ id: element, status: false });
        this.masipService.postRulesQueue(aux).subscribe(resp => {
          let index = apiCallsStatus.findIndex(e => e.id == element);
          apiCallsStatus[index].status = true;
        })
      });

      var interval = setInterval(() => {
        if (apiCallsStatus.every(v => v.status === true)) {
          clearInterval(interval);
          this.apiCallsStatus[3] = true;
        }
      }, 1000);
    } else {
      this.buildRuleObject();

      let aux = this.rule.apps;
      if (aux.length == 0) {
        this.rule.apps = [];
      } else {
        this.rule.apps = JSON.parse(aux);
      }

      this.masipService.postRulesQueue(this.rule).subscribe(resp => {
        this.apiCallsStatus[3] = true;
      }, error => {
        this.apiCallsStatus[3] = true;
      })
    }

  }

  buildRuleObject() {
    if (this.rulesEditor) {
      var userFiscalId = '';
      if (localStorage.getItem('accessAsUser') !== null) {
        userFiscalId = String(localStorage.getItem('accessAsUser') || '');
      }
      var days: string[] = [];
      if (this.rulesEditor.limitOption == 'DAYS') {
        let index = 0;
        this.rulesEditor.weekdays.forEach(element => {
          if (element) {
            days.push(this.rulesEditor.selectionDays[index]);
          }
          index++;
        });
      }
      var ranges = [];
      if (this.rulesEditor.limitOption == 'RANGES') {
        if (this.rulesEditor.timeZones[0] == true) {
          ranges.push('06-14');
        }
        if (this.rulesEditor.timeZones[1] == true) {
          ranges.push('14-22');
        }
        if (this.rulesEditor.timeZones[2] == true) {
          ranges.push('22-06');
        }
      }

      switch (this.rulesEditor.limitOption) {
        case 'DAYS':

          this.rule = {
            fiscal_id: userFiscalId,
            address: this.associatedIp,
            type: 'line',
            apps: JSON.stringify(this.rulesEditor.selectedApps),
            restriction_type: this.rulesEditor.limitOption,
            days: days
          }
          break;
        case 'RANGES':

          this.rule = {
            fiscal_id: userFiscalId,
            address: this.associatedIp,
            type: 'line',
            apps: JSON.stringify(this.rulesEditor.selectedApps),
            restriction_type: this.rulesEditor.limitOption,
            ranges: ranges
          }
          break;
        case 'CUSTOM':

          this.rule = {
            fiscal_id: userFiscalId,
            address: this.associatedIp,
            type: 'line',
            apps: JSON.stringify(this.rulesEditor.selectedApps),
            restriction_type: this.rulesEditor.limitOption,
            custom: this.rulesEditor.selectedHours
          }
          break;

        default:

          if (this.rule && this.rule.limitOption == null) {
            break;
          }
          this.rule = {
            fiscal_id: userFiscalId,
            address: this.associatedIp,
            type: 'line',
            apps: JSON.stringify(this.rulesEditor.selectedApps),
            restriction_type: this.rulesEditor.limitOption,
          }
          break;
      }
      if (this.rulesEditor.editRuleId != 0) {
        this.rule.id = this.rulesEditor.editRuleId;
      }
    }
  }

  openAddToMore() {
    var fiscalId = '';
    if (localStorage.getItem('accessAsUser') !== null) {
      fiscalId = String(localStorage.getItem('accessAsUser') || '');
    }
    if (this.associatedIp != '') {
      this.buildRuleObject();
    }
    const dialogRef = this.dialog.open(AddToMoreDialogComponent, {
      panelClass: 'add-more-dialog',
      data: {
        mode: 'lines',
        selectedDirection: this.telephone,
        settings: {
          fiscal_id: fiscalId,
          phoneNumber: this.telephone,
          services: this.settings
        },
        whitelist: {
          fiscal_id: fiscalId,
          phone_line: this.telephone,
          phonesNumbers: this.whitelist
        },
        blacklist: {
          fiscal_id: fiscalId,
          phone_line: this.telephone,
          phonesNumbers: this.blacklist
        },
        rule: this.rule,
        deletedRules: this.rulesEditor.deletingRules,
        ip: this.associatedIp

      }
    });
    return dialogRef.afterClosed().subscribe(result => {
      if (result === 'close') {
        this.close();
      }
    });

  }
}
