<div class="admin-settings-dialog-wrapper d-flex justify-content-center align-items-center flex-column">
  <p class="title">Ajustes de administrador</p>
  <i class="fal fa-times close-icon" (click)="close()"></i>
  <p class="label">Estado de la cola</p>
  <div class="custom-select">
    <div style="min-width: 205px; position: relative;">
      <i class=" far fa-chevron-down icon"></i>
      <mat-select [value]="status" (valueChange)="changeStatus($event)">
        <mat-option value="on">Activa</mat-option>
        <mat-option value="off">Inactiva</mat-option>
      </mat-select>
    </div>
  </div>
  <p class="label">Nº peticiones por minuto</p>
  <input type="number" [value]="requests_per_minute" (change)="changeRequests($event)">
  <p class="error-message">{{errorMessage}}</p>
  <app-accent-button (click)="confirm()" text="Confirmar" [selected]="true"></app-accent-button>
</div>