<div class="settings-wrapper">
  <div class="d-flex justify-content-between align-items-center w-100">
    <div class="d-flex align-items-center justify-content-start settings-header" style="gap: 13px;">
      <app-searchbar style="max-width: 620px; width: 620px;" (emitSearch)="searchData($event)"
        [placeholder]="currentView=='line' ? 'Buscar por estado o teléfono' : 'Buscar por estado o ip'"></app-searchbar>
      <div class="filter-date-wrapper d-flex align-items-center justify-content-between" (click)="picker.open()">
        <!-- <input class="datepicker-input" matInput [matDatepicker]="picker" placeholder="Filtrar por fecha modificación"> -->
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate #dateRangeStart placeholder="Filtrar por fecha modificación">
          <input matEndDate #dateRangeEnd (dateChange)="dateFilterChange(dateRangeStart, dateRangeEnd)">
        </mat-date-range-input>
        <i class="fal fa-calendar"></i>
      </div>
      <p class="reset-date" *ngIf="initialDate!='' || endDate != ''" (click)="resetDateFilter(picker)">Borrar filtro de
        fecha</p>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </div>
    <app-welcome-user></app-welcome-user>
  </div>
  <div class="content-wrapper">
    <p class="title">Últimas configuraciones</p>
    <div class="buttons-bar d-flex justify-content-between align-items-center w-100">
      <div class="d-flex align-items-center" style="gap: 8px;">
        <app-accent-button text="Fibra óptica" [selected]="currentView=='fiber'"
          (click)="changeView('fiber')"></app-accent-button>
        <app-accent-button text="Líneas telefónicas" [selected]="currentView=='line'"
          (click)="changeView('line')"></app-accent-button>
      </div>
    </div>


    <div *ngIf="loading" class="loading-wrapper">
      <mat-spinner></mat-spinner>
      <p>Cargando...</p>
    </div>

    <div>
      <div *ngFor="let item of queue; let i = index">
        <app-setting-line [item]="item" [even]="i%2!=0" (reloadQueue)="getQueue()"></app-setting-line>
      </div>

    </div>
  </div>
</div>