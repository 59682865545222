import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccentButtonComponent } from './accent-button/accent-button.component';
import { SidebarButtonComponent } from './sidebar-button/sidebar-button.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { WelcomeUserComponent } from './welcome-user/welcome-user.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { RulesEditorComponent } from './rules-editor/rules-editor.component';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../extra-modules/material.module';



@NgModule({
  declarations: [
    AccentButtonComponent,
    SidebarButtonComponent,
    SidebarComponent,
    SearchbarComponent,
    WelcomeUserComponent,
    PaginatorComponent,
    RulesEditorComponent
  ],
  exports: [
    AccentButtonComponent,
    SidebarButtonComponent,
    SidebarComponent,
    SearchbarComponent,
    WelcomeUserComponent,
    PaginatorComponent,
    RulesEditorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule
  ], schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule { }
