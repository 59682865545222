<div class="configure-telephone-wrapper">

  <div class="dialog-header">
    <div class="header-title-wrapper">
      <p class="title">Configurar línea telefónica - {{telephone}} <span><i *ngIf="pendingInQueue>0"
            class="fal fa-circle-exclamation"
            [matTooltip]="'Esta línea tiene '+ pendingInQueue +' configuraciones pendientes'"></i></span></p>
      <i class="fal fa-times" (click)="close()"></i>
    </div>
    <div class="header-buttons-tabs" [style]="loadingApi == true ? 'pointer-events: none; opacity: 0.5;' : ''">
      <app-accent-button text="Llamadas/SMS" [selected]="currentTab=='calls'"
        (click)="changeTab('calls')"></app-accent-button>
      <app-accent-button text="Desvíos" [selected]="currentTab=='forwarding'"
        (click)="changeTab('forwarding')"></app-accent-button>
      <app-accent-button text="Datos/internet" [selected]="currentTab=='internet'"
        (click)="changeTab('internet')"></app-accent-button>
      <app-accent-button text="Reglas" [selected]="currentTab=='rules'"
        (click)="changeTab('rules')"></app-accent-button>
      <app-accent-button text="Blacklist" [selected]="currentTab=='blacklist'"
        (click)="changeTab('blacklist')"></app-accent-button>
      <app-accent-button text="Whitelist" [selected]="currentTab=='whitelist'"
        (click)="changeTab('whitelist')"></app-accent-button>
    </div>
  </div>


  <div class="options-wrapper" *ngIf="!loadingApi">
    <div *ngFor="let item of settings; let i = index">
      <div class="line" *ngIf="displayedSettings.includes(item.featureID)">

        <div class="d-flex justify-content-between align-items-center w-100 config-line-resp" style="gap: 12px;">
          <p style="width: 80%;"
            *ngIf="item.featureID!='AireBarrado70X' && item.featureID!='AireBarrado_m2m' && item.featureID!='IMEI'">
            {{item.servicio}}</p>
          <p style="width: 80%;" *ngIf="item.featureID=='AireBarrado70X'">Bloqueo Llamadas Salientes 70X</p>
          <p style="width: 80%;" *ngIf="item.featureID=='AireBarrado_m2m'">Bloqueo Llamadas Salientes m2m</p>
          <p style="width: 80%;" *ngIf="item.featureID=='IMEI'">IMEI</p>
          <mat-slide-toggle *ngIf="withActive.includes(item.featureID)" [checked]="item.active=='true'"
            (change)="changeActive(i)"></mat-slide-toggle>

          <div class="custom-select d-flex justify-content-end align-items-center w-100" style="gap: 12px;"
            *ngIf="withSelectPhone.includes(item.featureID) && item.active=='true'">
            <div style="min-width: 205px; position: relative; gap: 10px;" class="d-flex flex-column">
              <i class=" far fa-chevron-down icon"></i>
              <mat-select [placeholder]="'Elegir'"
                [value]="(item.extString=='121' || item.extString=='34121' || item.extString=='34602209999' ||  item.extString==null) ? item.extString : ''"
                (valueChange)="extStringChange(i, $event)">
                <mat-option value="121">121: Buzón</mat-option>
                <mat-option value="34121">34121:
                  Buzón</mat-option>
                <mat-option value="34602209999">Aviso
                  SMS</mat-option>
                <mat-option value="">Otro teléfono</mat-option>
              </mat-select>
              <input [id]="'input-'+item.featureID" type="number" placeholder="Introduce número"
                [value]="item.extString"
                [style]="(item.extString!='121' && item.extString!='34121' && item.extString!='34602209999' && item.extString!=null) ? 'display: block;' : 'display: none; '"
                (change)="extStringChangeInput(i, $event)" [class]="item.extString==''? 'input-error' : ''">
            </div>

          </div>

          <div class="custom-select" *ngIf="withSelectRoaming.includes(item.featureID) && item.active=='true'">
            <i class="far fa-chevron-down icon"></i>
            <mat-select [placeholder]="'Elegir'" [value]="item.extString" (valueChange)="extStringChange(i, $event)">
              <mat-option *ngIf="item.featureID=='RoamingOutsidePLMN-CountryBarred'" value="RoamZ1CS">Activa Zona
                1</mat-option>
              <mat-option *ngIf="item.featureID=='RoamingOutsidePLMN-CountryBarred'" value="RoamZ3CS">Activa Zona 1, 2 y
                3</mat-option>
              <mat-option *ngIf="item.featureID=='RoamerAccessToHPLMN-AP-Barred'" value="RoamZ1PS">Activa Zona
                1</mat-option>
              <mat-option *ngIf="item.featureID=='RoamerAccessToHPLMN-AP-Barred'" value="RoamZ3PS">Activa Zona 1, 2 y
                3</mat-option>
            </mat-select>
          </div>

          <div class="custom-select d-flex flex-column" style="gap: 12px;"
            *ngIf="withSelectTime.includes(item.featureID) && item.active=='true'">
            <i class="far fa-chevron-down icon"></i>
            <mat-select [placeholder]="'Elegir'" (valueChange)="extNumberChange(i, $event)" [value]="item.extNumber">
              <mat-option value="5">5 segundos</mat-option>
              <mat-option value="10">10 segundos</mat-option>
              <mat-option value="15">15 segundos</mat-option>
              <mat-option value="20">20 segundos</mat-option>
              <mat-option value="25">25 segundos</mat-option>
              <mat-option value="30">30 segundos</mat-option>
              <mat-option value="35">35 segundos</mat-option>
              <mat-option value="40">40 segundos</mat-option>
              <mat-option value="45">45 segundos</mat-option>
            </mat-select>
            <input [id]="'input-'+item.featureID" type="number" placeholder="Introduce número" [value]="item.extString"
              *ngIf="withInput.includes(item.featureID) && item.active=='true' && item.featureID=='Cfnry'"
              (change)="extStringChangeInput(i, $event)" [class]="item.extString==''? 'input-error' : ''">
          </div>

          <div class="custom-select" *ngIf="withSelectShow.includes(item.featureID) && item.active=='true'">
            <i class="far fa-chevron-down icon"></i>
            <mat-select [placeholder]="'Elegir'" (valueChange)="extNumberChange(i, $event)" [value]="item.extNumber">
              <mat-option value="1">Ocultar</mat-option>
              <mat-option value="2">Mostrar</mat-option>
            </mat-select>
          </div>

          <input [id]="'input-'+item.featureID" type="number" placeholder="Introduce número" [value]="item.extString"
            *ngIf="withInput.includes(item.featureID) && item.active=='true' && item.featureID!='Cfnry'"
            (change)="extStringChangeInput(i, $event)" [class]="item.extString==''? 'input-error' : ''">

          <input [id]="'input-'+item.featureID" type="text" placeholder="Introduce número" [value]="item.extString"
            *ngIf="item.featureID=='IMEI'" (change)="extStringChangeInput(i, $event)">

          <div *ngIf="item.featureID=='LastUpdateLocation'">
            <input matInput [matDatepicker]="picker" (click)="picker.open()" readonly style="cursor: pointer;"
              [formControl]="date">
            <mat-datepicker #picker></mat-datepicker>
          </div>

          <div *ngIf="item.featureID=='VLR'" class="d-flex justify-content-end align-items-center double-input"
            style="gap: 12px;">
            <input type="text" placeholder="País" (change)="countryChange(i, $event)" [value]="item.country">
            <input type="text" placeholder="Nombre de red" (change)="networkChange(i, $event)"
              [value]="item.network_name">
          </div>
        </div>
      </div>
    </div>

    <div [style]="currentTab=='rules' ? 'display:block' : 'display: none'">
      <p *ngIf="associatedIp=='' && !loading" class="no-ip-text">Esta línea no tiene ninguna IP asociada</p>
      <div *ngIf="associatedIp!=''">
        <div style="flex: 1 1 auto; overflow-y: auto;">
          <app-rules-editor mode="line" [ip]="associatedIp" (deleteRuleEvent)="deleteRule($event)"
            (resetRule)="resetRule()"></app-rules-editor>
        </div>
      </div>
    </div>

    <div *ngIf="currentTab=='whitelist'">
      <div class="d-flex align-items-center justify-content-between white-black-list">
        <p class="title">Añadir número a la whitelist <span><i class="fal fa-circle-info"
              matTooltip="De haber algún número en la whitelist, esta línea solo permitirá llamadas de dichos números"></i></span>
        </p>
        <div class="d-flex align-items-center justify-content-end position-relative" style="gap: 12px;">
          <p *ngIf="errorBlackWhitelist==true" class="white-black-list-error-msg">El número ya está en la lista</p>
          <input type="number" id="whitelist-input" (input)="errorBlackWhitelist=false"
            [class]="errorBlackWhitelist==true ? 'error' : ''">
          <app-accent-button text="Añadir" [selected]="true" (click)="addToWhitelist()"></app-accent-button>
        </div>
      </div>
      <div class="line black-white-list-line" *ngFor="let phone of whitelist">
        <p>{{phone}}</p>
        <i class="fal fa-times" (click)="deleteFromWhitelist(phone)"></i>
      </div>
    </div>

    <div *ngIf="currentTab=='blacklist'">
      <div class="d-flex align-items-center justify-content-between white-black-list">
        <p class="title">Añadir número a la blacklist <span><i class="fal fa-circle-info"
              matTooltip="Esta línea no permitirá llamadas de ningún número que se encuentre en la blacklist"></i></span>
        </p>
        <div class="d-flex align-items-center justify-content-end position-relative" style="gap: 12px;">
          <p *ngIf="errorBlackWhitelist==true" class="white-black-list-error-msg">El número ya está en la lista</p>
          <input type="number" id="blacklist-input" [class]="errorBlackWhitelist==true ? 'error' : ''">
          <app-accent-button text="Añadir" [selected]="true" (click)="addToBlacklist()"></app-accent-button>
        </div>
      </div>
      <div class="line black-white-list-line" *ngFor="let phone of blacklist">
        <p>{{phone}}</p>
        <i class="fal fa-times" (click)="deleteFromBlacklist(phone)"></i>
      </div>
    </div>
  </div>


  <div *ngIf="loadingApi==true" class="loading-wrapper" style="flex: 1 1 auto;">
    <mat-spinner></mat-spinner>
    <p>Cargando...</p>
  </div>

  <div class="dialog-footer"
    [ngClass]="loadingApi==true || settings[26].extString=='' || settings[27].extString=='' || settings[28].extString=='' || settings[29].extString=='' || !checkRuleValid()? 'disabled' : ''">
    <div class="apply-many-button" (click)="openAddToMore()">
      <span class="text">Aplicar a varias líneas</span>
    </div>
    <app-accent-button (click)="saveChanges()" text="Guardar cambios" [selected]="true"></app-accent-button>
  </div>
</div>