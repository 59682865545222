import { AuthService } from './../../services/auth.service';
import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfigureFiberDialogComponent } from '../../dialogs/configure-fiber-dialog/configure-fiber-dialog.component';
import { SuccessDialogComponent } from '../../dialogs/success-dialog/success-dialog.component';
import { MasipService } from '../../services/masip.service';
import { ConfigureTelephoneLineComponent } from '../../dialogs/configure-telephone-line/configure-telephone-line.component';

@Component({
  selector: 'app-my-lines',
  templateUrl: './my-lines.component.html',
  styleUrl: './my-lines.component.scss'
})
export class MyLinesComponent {

  displayedColumns: string[] = ['num', 'actions'];
  data: any[] = [];
  currentView: string = 'fiber';
  errorMessage: string = '';

  loading: boolean = true;
  searched: string = '';

  actualPage: number = 1;
  totalPages: number = 1;

  userFiscalId: any;
  loadingApi: boolean = false;

  constructor(private dialog: MatDialog, private masipService: MasipService, private authService: AuthService) { }

  ngOnInit() {
    this.masipService.setApiLoadingGlobal(false);
    this.masipService.apiLoadingStorage.subscribe(resp => {
      this.loadingApi = resp;
    })
    if (localStorage.getItem('accessAsUser') !== null) {
      this.userFiscalId = String(localStorage.getItem('accessAsUser') || '');
    }

    this.loadLines();

  }

  changeView(view: string) {
    this.currentView = view;
    switch (view) {
      case 'fiber':

        this.loadLines();
        break;
      case 'lines':
        this.loadLines();
        break;

      default:
        break;
    }
  }

  getHeader(): string {
    switch (this.currentView) {
      case 'lines':
        return 'Nº Teléfono';
      case 'fiber':
        return 'Nº IP'
      default:
        return ''
    }
  }

  getData(element: any): any {
    if (element) {
      switch (this.currentView) {
        case 'lines':
          return element.telefono;
        case 'fiber':
          if (element.ip) {
            return element.ip;
          } else {
            return 'Router sin IP asignada';
          }
        default:
          return ''
      }
    } else {
      return '';
    }
  }

  searchData(by: any) {
    this.searched = by;
    this.loadLines(1, by);

  }

  changePage(page: number) {
    this.loadLines(page, this.searched);
  }

  loadLines(page: number = 1, search: string = '') {
    this.loading = true;
    this.masipService.setApiLoadingGlobal(false);
    this.masipService.setApiLoading(true);

    switch (this.currentView) {
      case 'lines':
        this.masipService.getLinesByUser(9, page, this.userFiscalId, 0, search).subscribe(resp => {
          this.data = resp.data.data;
          this.totalPages = resp.data.total_pages;
          this.actualPage = resp.data.current_page;
          this.loading = false;
          this.masipService.setApiLoading(false);
          // this.authService.refresh().subscribe(resp => { });

        },
          error => {
            this.loading = false;
            this.masipService.setApiLoading(false);

          })
        break;
      case 'fiber':
        this.masipService.getFiberByUser(9, page, this.userFiscalId, search).subscribe(resp => {
          this.data = resp.data.data;
          this.totalPages = resp.data.total_pages;
          this.actualPage = resp.data.current_page;
          this.loading = false;
          this.masipService.setApiLoading(false);
          // this.authService.refresh().subscribe(resp => { });

        },
          error => {
            this.loading = false;
            this.masipService.setApiLoading(false);

          })
        break;
      default:
        break;
    }

  }

  openConfiguration(element: any) {
    if (this.currentView == 'fiber') {
      const dialogRef = this.dialog.open(ConfigureFiberDialogComponent, {
        panelClass: 'big-dialog',
        data: {
          ip: element.ip,
        }
      });
      return dialogRef.afterClosed();
    }
    if (this.currentView == 'lines') {
      const dialogRef = this.dialog.open(ConfigureTelephoneLineComponent, {
        panelClass: 'big-dialog',
        data: {
          telephone: element.telefono,
        }
      });
      return dialogRef.afterClosed();
    }
    return '';
  }
}
