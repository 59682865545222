import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MasipService } from '../../services/masip.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.scss'
})
export class ClientsComponent {

  displayedColumns: string[] = ['name', 'dni', 'phone', 'actions'];
  data: any[] = [];

  loading: boolean = true;
  searched: string = '';

  actualPage: number = 1;
  totalPages: number = 1;
  loadingApi: boolean = false;
  @ViewChild('searchbar') searchbar!: ElementRef;

  constructor(private dialog: MatDialog, private masipService: MasipService, private router: Router, private authService: AuthService) { }

  ngOnInit() {
    this.masipService.setApiLoadingGlobal(false);
    localStorage.removeItem('accessAsUser');
    this.masipService.setUser(false);
    this.masipService.apiLoadingStorage.subscribe(resp => {
      this.loadingApi = resp;
    })
    this.loadLines();
  }

  searchData(by: any) {
    this.searched = by;
    this.loadLines(1, by);
  }

  changePage(page: number) {
    this.loadLines(page, this.searched);
  }

  loadLines(page: number = 1, search: string = '') {
    this.loading = true;
    this.masipService.setApiLoading(true);
    this.masipService.getClients(9, page, search).subscribe(resp => {
      this.data = resp.data.data;
      this.totalPages = resp.data.total_pages;
      this.actualPage = resp.data.current_page;
      this.loading = false;
      this.masipService.setApiLoading(false);
      // this.authService.refresh().subscribe(resp => { });
    }, error => {

      this.loading = false;
      this.masipService.setApiLoading(false);
    })
  }

  enterAsUser(user: any) {
    this.masipService.setApiLoadingGlobal(true);
    this.router.navigateByUrl('/mis-lineas');
    localStorage.setItem('accessAsUser', user.fiscalId);
    this.masipService.setUser(true);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);
  }
}
