import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MasipService } from '../../../../services/masip.service';
import { error } from 'node:console';

@Component({
  selector: 'app-setting-line',
  templateUrl: './setting-line.component.html',
  styleUrl: './setting-line.component.scss'
})
export class SettingLineComponent {
  @Input() item: any;
  @Input() even: boolean = false;
  @Output() reloadQueue = new EventEmitter<any>();

  admin: boolean = false;
  constructor(private authService: AuthService, private dialog: MatDialog, private masipService: MasipService) { }

  ngOnInit() {
    this.authService.getUserMe().subscribe(resp => {
      if (resp.data.admin) {
        this.admin = true;
      }
    })
  }

  getServices(data: any): any[] {
    var aux = JSON.parse(data);
    return aux;

  }

  formatDate(date: string): string {
    var auxDate = date.split('T')[0].split('-');
    var auxHour = date.split('T')[1].substring(0, 5);

    return auxDate[2] + '/' + auxDate[1] + '/' + auxDate[0] + ' - ' + auxHour;
  }

  getPhoneNumbers(data: any): any[] {
    var aux = JSON.parse(data);
    return aux;
  }

  executeNow() {
    let message = '¿Seguro que quieres ejecutar esta configuración?';
    let confirmButton = true;
    this.masipService.getQueueById(this.item.id).subscribe(resp => {

      if (resp.status === 'COMPLETADA' || resp.status === 'ERROR') {
        message = 'Esta configuración ya ha sido ejecutada o cancelada';
        confirmButton = false;
      }

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        panelClass: 'success-dialog',
        data: {
          message: message,
          confirm: confirmButton
        }
      });
      return dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result === 1) {

            this.masipService.setApiLoadingGlobal(true);
            this.masipService.executeFromQueue(this.item.id).subscribe(resp => {
              this.masipService.setApiLoadingGlobal(false);
              this.reloadQueue.emit();
            }, error => {
              this.masipService.setApiLoadingGlobal(false);
              this.reloadQueue.emit();
            });
          }
        }
      });
    })

  }

  cancel() {
    let message = '¿Seguro que quieres cancelar esta configuración?';
    let confirmButton = true;
    this.masipService.getQueueById(this.item.id).subscribe(resp => {
      if (resp.status === 'COMPLETADA' || resp.status === 'ERROR') {
        message = 'Esta configuración ya ha sido ejecutada o cancelada';
        confirmButton = false;
      }
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        panelClass: 'success-dialog',
        data: {
          message: message,
          confirm: confirmButton
        }
      });
      return dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result === 1) {
            this.masipService.setApiLoadingGlobal(true);
            this.masipService.cancelFromQueue(this.item.id).subscribe(resp => {
              this.masipService.setApiLoadingGlobal(false);
              this.reloadQueue.emit();
            }, error => {
              this.masipService.setApiLoadingGlobal(false);
              this.reloadQueue.emit();
            });
          }
          this.reloadQueue.emit();
        }
      });
    })

  }

  getColorStatus(status: string) {
    switch (status) {
      case 'SOLICITADA':
        return 'background: var(--color-warning);';
      case 'ERROR':
        return 'background: var(--color-danger);';
      case 'CANCELADA':
        return 'background: var(--color-danger);';
      case 'COMPLETADA':
        return 'background: var(--color-success);';
      default:
        return '';
    }

  }

  translateType(type: string) {
    switch (type) {
      case 'DENY':
        return 'No permitir nunca';
      case 'L-V':
        return 'De Lunes a Viernes';
      case 'OFFICE':
        return 'Horario oficina';
      case 'DAYS':
        return 'Por días concretos';
      case 'RANGES':
        return 'Por franjas horarias';
      case 'CUSTOM':
        return 'Horario personalizado';
      default:
        return '';
    }
  }
}
