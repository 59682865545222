import { Component, Inject } from '@angular/core';
import { MasipService } from '../../services/masip.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { interval } from 'rxjs';

@Component({
  selector: 'app-add-to-more-dialog',
  templateUrl: './add-to-more-dialog.component.html',
  styleUrl: './add-to-more-dialog.component.scss'
})
export class AddToMoreDialogComponent {
  loading: boolean = false;
  mode: string = '';
  data: any[] = [];
  selectedDirections: any[] = [];
  actualPage: number = 1;
  totalPages: number = 1;
  userFiscalId: string = '';
  rule: any;
  whitelist: any;
  blacklist: any;
  settings: any;
  loadingApi: boolean = false;
  deletedRules: any[] = [];

  apiCallsStatus: any[] = [];

  constructor(private masipService: MasipService, @Inject(MAT_DIALOG_DATA) public dialogData: any, private dialogRef: MatDialogRef<AddToMoreDialogComponent>, private dialog: MatDialog) {
    if (localStorage.getItem('accessAsUser') !== null) {
      this.userFiscalId = String(localStorage.getItem('accessAsUser') || '');
    }
    this.mode = this.dialogData.mode;
    this.rule = this.dialogData.rule;
    this.whitelist = this.dialogData.whitelist;
    this.blacklist = this.dialogData.blacklist;
    this.settings = this.dialogData.settings;
    this.selectedDirections.push(this.dialogData.selectedDirection);
  }

  ngOnInit() {
    this.masipService.apiLoadingStorage.subscribe(resp => {
      this.loadingApi = resp;
    })
    this.loadLines();
  }

  close() {
    this.dialogRef.close();
  }

  addDirection(direction: string) {
    if (!this.selectedDirections.includes(direction)) {
      this.selectedDirections.push(direction);
    } else {
      const index = this.selectedDirections.indexOf(direction);
      if (index > -1) {
        this.selectedDirections.splice(index, 1);
      }
    }
  }

  confirm() {
    this.masipService.setApiLoadingGlobal(true);
    if (this.rule) {
      this.rule.apps = JSON.parse(this.rule.apps);
    }
    if (this.deletedRules.length > 0) {
      this.deleteRules(this.dialogData.ip);
    }
    this.selectedDirections.forEach((element, index) => {
      if (this.rule && this.deletedRules.length == 0) {
        switch (this.mode) {
          case 'lines':
            var item = this.data.find(({ telefono }) => telefono === element);
            if (item.ip) {
              this.rule.address = item.ip;
              this.apiCallsStatus.push({ id: element, call: 'rule', finished: false });
              this.masipService.postRulesQueue(this.rule).subscribe(resp => {
                let index = this.apiCallsStatus.findIndex(e => e.id == element && e.call == 'rule');
                this.apiCallsStatus[index].finished = true;

              }, error => {
                let index = this.apiCallsStatus.findIndex(e => e.id == element && e.call == 'rule');
                this.apiCallsStatus[index].finished = true;
              });
            }
            break;
          case 'fiber':
            this.rule.address = element;
            this.apiCallsStatus.push({ id: element, call: 'rule', finished: false });
            this.masipService.postRulesQueue(this.rule).subscribe(resp => {
              let index = this.apiCallsStatus.findIndex(e => e.id == element && e.call == 'rule');
              this.apiCallsStatus[index].finished = true;
            }, error => {
              let index = this.apiCallsStatus.findIndex(e => e.id == element && e.call == 'rule');
              this.apiCallsStatus[index].finished = true;
            });
            break;
          default:
            break;
        }
      }
      if (this.settings) {
        this.settings.phoneNumber = element;
        this.apiCallsStatus.push({ id: element, call: 'settings', finished: false });
        this.masipService.postConfigLineQueue(this.settings).subscribe(resp => {
          let index = this.apiCallsStatus.findIndex(e => e.id == element && e.call == 'settings');
          this.apiCallsStatus[index].finished = true;
        }, error => {
          let index = this.apiCallsStatus.findIndex(e => e.id == element && e.call == 'settings');
          this.apiCallsStatus[index].finished = true;
        });
      }
      if (this.whitelist) {
        this.whitelist.phone_line = element;
        this.apiCallsStatus.push({ id: element, call: 'whitelist', finished: false });
        this.masipService.postWhitelistQueue(this.whitelist).subscribe(resp => {
          let index = this.apiCallsStatus.findIndex(e => e.id == element && e.call == 'whitelist');
          this.apiCallsStatus[index].finished = true;
        }, error => {
          let index = this.apiCallsStatus.findIndex(e => e.id == element && e.call == 'whitelist');
          this.apiCallsStatus[index].finished = true;
        });
      }
      if (this.blacklist) {
        this.blacklist.phone_line = element;
        this.apiCallsStatus.push({ id: element, call: 'blacklist', finished: false });
        this.masipService.postBlacklistQueue(this.blacklist).subscribe(resp => {
          let index = this.apiCallsStatus.findIndex(e => e.id == element && e.call == 'blacklist');
          this.apiCallsStatus[index].finished = true;
        }, error => {
          let index = this.apiCallsStatus.findIndex(e => e.id == element && e.call == 'blacklist');
          this.apiCallsStatus[index].finished = true;
        });
      }

    });

    var interval = setInterval(() => {
      if (this.apiCallsStatus.every(v => v.finished === true)) {
        this.masipService.setApiLoadingGlobal(false);
        clearInterval(interval);
        this.dialogRef.close('close');
        const dialogRef = this.dialog.open(SuccessDialogComponent, {
          panelClass: 'success-dialog',
        });
      }
    }, 1000);
  }

  loadLines(page: number = 1) {
    this.loading = true;
    this.masipService.setApiLoading(true);

    switch (this.mode) {
      case 'lines':
        this.masipService.getLinesByUser(9, page, this.userFiscalId, 1, '').subscribe(resp => {
          var aux = this.data;
          this.data = aux.concat(resp.data.data);
          this.totalPages = resp.data.total_pages;
          this.actualPage = resp.data.current_page;
          this.loading = false;
          this.masipService.setApiLoading(false);
        },
          error => {
            this.loading = false;
            this.masipService.setApiLoading(false);
          })
        break;
      case 'fiber':
        this.masipService.getFiberByUser(9, page, this.userFiscalId, '').subscribe(resp => {
          var aux = this.data;
          this.data = aux.concat(resp.data.data);
          this.totalPages = resp.data.total_pages;
          this.actualPage = resp.data.current_page;
          this.loading = false;
          this.masipService.setApiLoading(false);
        },
          error => {
            this.loading = false;
            this.masipService.setApiLoading(false);
          })
        break;
      default:
        break;
    }

  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && this.actualPage <= this.totalPages) {
      this.actualPage++;
      this.loadLines(this.actualPage);
    }
  }

  deleteRules(address: string) {
    var userFiscalId = '';
    if (localStorage.getItem('accessAsUser') !== null) {
      userFiscalId = String(localStorage.getItem('accessAsUser') || '');
    }
    var apiCallsStatus: any[] = [];
    this.masipService.setApiLoadingGlobal(true);
    var aux = {
      id: 0,
      fiscal_id: userFiscalId,
      address: address,
      type: 'fiber',
      apps: [],
      restriction_type: null,
    }
    this.deletedRules.forEach(element => {
      aux.id = element;
      apiCallsStatus.push({ id: element, status: false });
      this.masipService.postRulesQueue(aux).subscribe(resp => {
        let index = apiCallsStatus.findIndex(e => e.id == element);
        apiCallsStatus[index].status = true;
      })
    });

    var interval = setInterval(() => {
      if (apiCallsStatus.every(v => v.status === true)) {
        clearInterval(interval);
        this.masipService.setApiLoadingGlobal(false);
        this.dialogRef.close();
        const dialogRef = this.dialog.open(SuccessDialogComponent, {
          panelClass: 'success-dialog',
        });
        return dialogRef.afterClosed();
      }
      return;
    }, 1000);
  }
}
