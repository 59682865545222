<div class="setting-line-wrapper"
  [style]="even ? 'background: var(--color-primary);' : 'background: var(--color-secondary);'">
  <div class="status-section d-flex align-items-center justify-content-end">
    <div *ngIf="admin==true && item.status === 'SOLICITADA'" class="admin-action" style="color: var(--color-success);"
      (click)="executeNow()">
      <i class="far fa-play"></i>
      <p class="admin-action-text">Lanzar ya</p>
    </div>
    <div *ngIf="item.status === 'SOLICITADA'" class="admin-action" style="color: var(--color-danger);"
      (click)="cancel()">
      <i class="far fa-circle-stop"></i>
      <p class="admin-action-text">Cancelar</p>
    </div>
    <div class="status-chip" [style]="getColorStatus(item.status)">
      <p>{{item.status}}</p>
    </div>
  </div>
  <p class="title" *ngIf="item.config_line?.length>0">{{item.config_line[0].phone_number}}</p>
  <p class="title" *ngIf="item.blacklist?.length>0">{{item.blacklist[0].phoneNumber}}</p>
  <p class="title" *ngIf="item.whitelist?.length>0">{{item.whitelist[0].phoneNumber}}</p>
  <p class="title" *ngIf="item.config_restriction_rules?.length>0">{{item.config_restriction_rules[0].address}}</p>
  <div class="d-flex justify-content-start align-items-center dates-wrapper" style="gap: 31px;">
    <p class="title"><span class="label">Modificación: </span> {{formatDate(item.created_at)}}</p>
    <p class="title"><span class="label">Validación: </span> <span>{{item.status === 'SOLICITADA' ? 'En proceso' :
        formatDate(item.updated_at)}}</span></p>
  </div>
  <div style="margin-top: 16px;">
    <div *ngIf="item.config_line?.length>0">
      <div class="configuration-line" *ngFor="let line of getServices(item.config_line[0].services)">
        <!-- <span class="waiting">SOLICITADA</span> -->
        <span>{{line.servicio}}</span>
      </div>
    </div>
    <div *ngIf="item.whitelist?.length>0">
      <p class="queue-title">Whitelist:</p>
      <p class="queue-subtitle" *ngIf="getPhoneNumbers(item.whitelist[0].add_phoneNumber).length>0">Añadidos</p>
      <p class="queue-text" *ngFor="let number of getPhoneNumbers(item.whitelist[0].add_phoneNumber)">{{number}}</p>
      <p class="queue-subtitle" *ngIf="getPhoneNumbers(item.whitelist[0].delete_phoneNumber).length>0">Borrados</p>
      <p class="queue-text" *ngFor="let number of getPhoneNumbers(item.whitelist[0].delete_phoneNumber)">{{number}}</p>
    </div>
    <div *ngIf="item.blacklist?.length>0">
      <p class="queue-title">Blacklist:</p>
      <p class="queue-subtitle" *ngIf="getPhoneNumbers(item.blacklist[0].add_phoneNumber).length>0">Añadidos</p>
      <p class="queue-text" *ngFor="let number of getPhoneNumbers(item.blacklist[0].add_phoneNumber)">{{number}}</p>
      <p class="queue-subtitle" *ngIf="getPhoneNumbers(item.blacklist[0].delete_phoneNumber).length>0">Borrados</p>
      <p class="queue-text" *ngFor="let number of getPhoneNumbers(item.blacklist[0].delete_phoneNumber)">{{number}}</p>
    </div>
    <div *ngIf="item.config_restriction_rules?.length>0">
      <p *ngIf="item.config_restriction_rules[0].restriction_type==null" class="rule-name">Regla eliminada</p>
      <p *ngIf="item.config_restriction_rules[0].restriction_type!=null" class="rule-name">
        {{translateType(item.config_restriction_rules[0].restriction_type)}}<span
          *ngFor="let app of getServices(item.config_restriction_rules[0].apps)"> -
          {{app}}</span></p>
    </div>
  </div>
</div>