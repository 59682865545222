import { Component, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MasipService } from '../../services/masip.service';

@Component({
  selector: 'app-panel-router',
  templateUrl: './panel-router.component.html',
  styleUrl: './panel-router.component.scss'
})
export class PanelRouterComponent {
  loadingApi: boolean = false;
  @ViewChild('drawer') drawer: any;
  constructor(protected router: Router, private authService: AuthService, private masipService: MasipService) { }

  ngOnInit() {
    this.masipService.apiLoadingGlobalStorage.subscribe(resp => {
      this.loadingApi = resp;
    })
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.drawer.close();
      }
    });
  }
}
