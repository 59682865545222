import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MasipService } from '../../services/masip.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-rules-editor',
  templateUrl: './rules-editor.component.html',
  styleUrl: './rules-editor.component.scss'
})
export class RulesEditorComponent {
  @Input() ip: string = '';
  @Input() mode: string = '';
  @Output() deleteRuleEvent = new EventEmitter<any>();
  @Output() resetRule = new EventEmitter<any>();
  rules: any[] = [];
  editing: boolean = false;

  //apps
  selectedApps: string[] = [];
  apps: any[] = ['INSTAGRAM', 'PINTEREST', 'FACEBOOK', 'SNAPCHAT', 'WHATSAPP', 'YOUTUBE', 'TWITTER', 'TIKTOK'];

  limitOption: string = '';
  editRuleId: number = 0;

  //days
  weekdays: boolean[] = [false, false, false, false, false, false, false];
  //hours
  timeZones: boolean[] = [false, false, false];

  selectedHours: any[] = [];
  hours: string[] = ['6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '00:00', '01:00', '02:00', '03:00', '04:00', '05:00'];
  days: string[] = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  selectionDays: string[] = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
  selectionHours: string[] = ['6-7', '7-8', '8-9', '9-10', '10-11', '11-12', '12-13', '13-14', '14-15', '15-16', '16-17', '17-18', '18-19', '19-20', '20-21', '21-22', '22-23', '23-24', '0-1', '1-2', '2-3', '3-4', '4-5', '5-6'];

  loading: boolean = false;
  deletingRules: number[] = [];

  lastLimitOption: string = '';


  constructor(private masipService: MasipService, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.loading = true;
    // this.masipService.setApiLoading(true);
    this.masipService.getRules(this.ip, this.mode).subscribe(resp => {
      this.rules = resp.data;
      this.loading = false;
      // this.masipService.setApiLoading(false);

    }, error => {
      this.loading = false;
      // this.masipService.setApiLoading(false);
    })
  }

  back() {
    this.limitOption = '';
    this.selectedHours = [];
    this.selectedApps = [];
    this.weekdays = [false, false, false, false, false, false, false];
    this.timeZones = [false, false, false];
    this.editing = false;
    this.resetRule.emit();
  }

  jsonParser(json: any): any {
    return JSON.parse(json);
  }

  deleteRule(rule: any) {
    //check if rule is in deletingrules, if so delete it, if not add it
    if (this.deletingRules.includes(rule.id)) {
      const index = this.deletingRules.indexOf(rule.id);
      if (index > -1) {
        this.deletingRules.splice(index, 1);
      }
    } else {
      this.deletingRules.push(rule.id);
    }
    //  this.deleteRuleEvent.emit(null);
    // this.deletingRule = rule.id;
    // this.deleteRuleEvent.emit(rule);
  }

  // cancelDeleteRule(rule: any) {
  //   if (this.deletingRule == rule.id) {
  //     this.deletingRule = 0;
  //     return;
  //   }
  //   this.deletingRule = rule.id;
  //   this.deleteRuleEvent.emit(null);
  // }

  editRule(rule: any) {
    this.editing = true;
    this.limitOption = rule.restriction_type;
    this.editRuleId = rule.id;
    this.selectedApps = this.jsonParser(rule.apps);
    switch (this.limitOption) {
      case 'DAYS':
        rule.days.forEach((element: string) => {
          if (element == 'L') {
            this.weekdays[0] = true;
          }
          if (element == 'M') {
            this.weekdays[1] = true;
          }
          if (element == 'X') {
            this.weekdays[2] = true;
          }
          if (element == 'J') {
            this.weekdays[3] = true;
          }
          if (element == 'V') {
            this.weekdays[4] = true;
          }
          if (element == 'S') {
            this.weekdays[5] = true;
          }
          if (element == 'D') {
            this.weekdays[6] = true;
          }
        });
        break;
      case 'RANGES':
        rule.ranges.forEach((element: string) => {
          if (element == '06-14') {
            this.timeZones[0] = true;
          }
          if (element == '14-22') {
            this.timeZones[1] = true;
          }
          if (element == '22-06') {
            this.timeZones[2] = true;
          }
        });
        break;
      case 'CUSTOM':
        this.selectedHours = rule.custom;
        break;
      default:
        break;
    }
  }

  translateType(type: string) {
    switch (type) {
      case 'DENY':
        return 'No permitir nunca';
      case 'L-V':
        return 'De Lunes a Viernes';
      case 'OFFICE':
        return 'Horario oficina';
      case 'DAYS':
        return 'Por días concretos';
      case 'RANGES':
        return 'Por franjas horarias';
      case 'CUSTOM':
        return 'Horario personalizado';
      default:
        return '';
    }
  }

  aux() {
    return this.selectedHours;
  }

  selectTimetable(time: any) {
    var aux = {
      day: this.selectionDays[this.days.indexOf(time.day)],
      hour: this.selectionHours[this.hours.indexOf(time.hour)]
    }
    if (this.isHourSelected(time)) {
      const index = this.selectedHours.findIndex(e => e.day === aux.day && e.hour === aux.hour);
      if (index > -1) {
        this.selectedHours.splice(index, 1);
      }
    } else {
      this.selectedHours.push(aux);
    }

  }

  isHourSelected(time: any): boolean {
    var aux = {
      day: this.selectionDays[this.days.indexOf(time.day)],
      hour: this.selectionHours[this.hours.indexOf(time.hour)]
    }

    return this.selectedHours.some(e => e.day === aux.day && e.hour === aux.hour);
  }


  changeApp(event: any, app: string) {
    if (event.checked) {
      this.selectedApps.push(app);
    } else {
      const index = this.selectedApps.indexOf(app);
      if (index > -1) {
        this.selectedApps.splice(index, 1);
      }
    }
  }

  radioValueChanged() {
    if (this.lastLimitOption == '') {
      this.lastLimitOption = this.limitOption;
      return;
    }

    if (this.lastLimitOption == 'CUSTOM') {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        panelClass: 'success-dialog',
        data: {
          message: 'Si cambias el tipo de restricción se perderán los horarios seleccionados. ¿Estás seguro?',

        }
      });
      return dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result && result === true) {
          this.doChangeRadio(this.limitOption);
        } else {
          this.limitOption = this.lastLimitOption;
        }
      });

    } else {
      this.doChangeRadio(this.limitOption);
      return;
    }
  }

  doChangeRadio(mode: string) {
    this.limitOption = mode;
    this.lastLimitOption = mode;
    switch (mode) {
      case 'DAYS':
        this.timeZones = [false, false, false];
        this.selectedHours = [];
        break;
      case 'RANGES':
        this.weekdays = [false, false, false, false, false, false, false];
        this.selectedHours = [];
        break;
      case 'CUSTOM':
        this.weekdays = [false, false, false, false, false, false, false];
        this.timeZones = [false, false, false];
        break;
      default:
        break;
    }
  }

  capitalize(string: string): string {
    let aux = string.split(' ');
    for (let i = 0; i < aux.length; i++) {
      if (aux[i] !== '') {
        aux[i] = aux[i][0]?.toUpperCase() + aux[i]?.substr(1);
      }
    }
    return aux.join(' ');
  }

  truncate3chars(day: string): string {
    if (window.innerWidth <= 1024) {
      return day.substring(0, 3);
    } else {
      return day;
    }
  }
}

