<div class="configure-fiber-wrapper">

  <div class="dialog-header">
    <div class="header-title-wrapper">
      <p class="title" *ngIf="ip">Configurar fibra óptica - {{ip}}<span><i *ngIf="pendingInQueue>0"
            class="fal fa-circle-exclamation"
            [matTooltip]="'Esta ip tiene '+ pendingInQueue +' configuraciones pendientes'"></i></span>
      </p>
      <p class="title" *ngIf="!ip">Router sin IP asignada</p>
      <i class="fal fa-times" (click)="close()"></i>
    </div>
    <div class="header-buttons-tabs" *ngIf="ip">
      <app-accent-button text="Reglas" [selected]="true"></app-accent-button>
      <!-- <app-accent-button text="Limitación de datos" [selected]="false"></app-accent-button> -->
    </div>
  </div>

  <div *ngIf="!ip" class="no-ip-on-router">
    <p>Para utilizar el portal de restricciones, es necesario conectar el router</p>
  </div>

  <div style="flex: 1 1 auto; overflow-y: auto;" *ngIf="ip">
    <app-rules-editor mode="fiber" [ip]="ip" (deleteRuleEvent)="deleteRule($event)"></app-rules-editor>
  </div>
  <div class="dialog-footer" [ngClass]="loadingApi==true ||  !checkRuleValid() ? 'disabled' : ''" *ngIf="ip">
    <div class="apply-many-button" (click)="openAddToMore()">
      <span class="text">Aplicar a varias IP</span>
    </div>
    <app-accent-button text="Guardar cambios" [selected]="true" (click)="finish()"></app-accent-button>
  </div>
</div>