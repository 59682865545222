import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(private activatedRouter: ActivatedRoute, private router: Router, private authService: AuthService) { }
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
  //   throw new Error('Method not implemented.');
  // }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    var token = localStorage.getItem('jwt_token');
    if (token === null) {
      return this.hasError();
    }
    return this.authService.getUserMe().pipe(map((response: any) => {

      if (response) {
        return true;
      } else {
        return this.hasError();
      }
    }), catchError(err => { return of(this.hasError()) }));

  }

  hasError() {
    this.router.navigate(['/login']).then(() => this.authService.logout());
    return false;
  }

}
