import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss'
})
export class ConfirmationDialogComponent {

  message: string = '';
  confirmButton: boolean = true;

  constructor(private dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.message = this.data.message;
    this.confirmButton = this.data.confirm;

  }

  close() {
    if (this.message == 'Esta configuración ya ha sido ejecutada o cancelada') {
      this.dialogRef.close(0);
      return;
    }
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close(1);
  }
}
