import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrl: './success-dialog.component.scss'
})
export class SuccessDialogComponent {

  constructor(private router: Router, private dialogRef: MatDialogRef<SuccessDialogComponent>) { }

  goToConfig() {
    this.dialogRef.close();
    this.router.navigateByUrl('/configuraciones');
  }
  close() {
    this.dialogRef.close();
  }

}
